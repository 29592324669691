.btn {
    @include space(margin, all, $zero);
    text-transform: $none !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active {
    @include primary-btn(20.938rem);
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
    @include secondary-btn(20.938rem);
}

.btn-small,
.btn-small:hover,
.btn-small:active {
    max-width: 10rem;
}

@media only screen and (min-width: 48rem) {
    .btn-medium,
    .btn-medium:hover,
    .btn-medium:active {
        max-width: 18.9375rem;
    }
}
