.reassign-agent-search-heading {
    padding-bottom: 2.875rem;
    h1 {
        width: $percentage * 10;
        display: block;
    }
    a {
        @include space(margin, top, $mediumhlf);
        color: #0071ad;
        text-decoration: underline;
    }
}

img.back-arrow-icon {
    margin: 0 11px 0 0;
}

.disable-icon {
    border-bottom: 0.125rem solid map-get($theme-colors, "medium-grey");
    width: 0.75rem;
    height: 0.125rem;
    display: map-get($display, "inline-block");
}

.success-icon {
    display: map-get($display, "inline-block");
}

.font-bold {
    font-weight: bold;
}

.date-50 {
    width: 3rem;
    float: $left;
    position: map-get($position, "relative");
    text-align: right;
    @include space(margin, right, $small);
}

.date-bold-mobile {
    font-weight: bold;
    font-size: 1.125rem !important;
    display: map-get($display, "block");
}

.date-year,
.first-end-date {
    display: map-get($display, "block");
    font-size: 0.75rem;
    padding: 0 0 0 1rem;
}

.date-sign {
    font-weight: bold;
    position: map-get($position, "absolute");
    right: -0.875rem;
    top: -0.25rem;
}

.reassign-btn-section {
    width: 220px;
    float: right;
    :disabled {
        color: #171717;
        background-color: #ced2d7;
        border-color: #ced2d7;
        font-weight: bold;
    }
}

.agent-search-container {
    display: inline-block;
}

.reassign-btn-section button.btn.btn-primary {
    padding: 0 1rem;
    margin-bottom: 1px;
}

.termed-first {
    width: 210px;
}

.termed-reassigned {
    width: 210px;
}

.select-all-section label {
    color: map-get($theme-colors, "grey");
}

.select-all-section {
    margin: 15px 0 0 0;
}

.created-lpadd {
    padding-left: 7px !important;
}

.lpadd-live {
    padding-left: 5px !important;
}

span.first-end-date {
    padding-left: 1.1rem;
}

.font-18 {
    font-size: 1.125rem !important;
}

// Large screen Desktop Ipad and large
@media only screen and (min-width: 50rem) {
    .onoffswitch {
        top: 6px;
        // left: -43px !important;
        margin-left: -1.5rem;
    }
    .on-off-text {
        display: none !important;
    }

    .showMobile {
        display: none;
    }
    .showDesktop {
        display: block;
    }

    .first-site {
        font-size: 0.75rem;
        font-weight: 400;
    }

    .l-height {
        line-height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    .admin-selection-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 1.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                width: 8.875rem;
            }

            .termed-padd {
                padding-left: 25px;
            }
            th:first-child {
                width: 5.625rem;
            }
            th:nth-child(2) {
                width: 12rem;
            }
            th:nth-child(3) {
                width: 6rem;
            }
            th:nth-child(4) {
                width: 10.2rem;
            }
            th:nth-child(5) {
                width: 3.8rem;
            }
            th:nth-child(6) {
                width: 3.2rem;
            }
            th:nth-child(7) {
                width: 3.2rem;
            }
            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

.mar-right1 {
    margin-right: 97px !important;
}

//Small Screen

@media only screen and (max-width: 50rem) {
    .onoffswitch {
        top: 6px;
        // margin-left: -1.5rem;
    }
    tr.padding-section {
        height: 60px;
    }
    .reassign-table-first-column {
        position: relative !important;
        left: 33px !important;
        top: 5px !important;
    }

    .termed-reassigned {
        width: 252px !important;
    }

    .admin-reassign-data-table {
        margin-top: $zero !important;
    }
    .reassign-agent-search-heading {
        padding-bottom: 1.875rem;
    }

    .reassign-agent-search-heading a {
        text-decoration: underline;
    }

    span.first-site-mobile {
        font-size: 0.75rem;
        font-weight: 400;
    }
    span.date-bold-mobile.l-height {
        line-height: 1.2rem !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    span.on-off-text {
        position: absolute;
        right: 1.9rem;
        font-size: 0.75rem;
        color: #555;
        display: block;
    }

    .termed-on-off {
        width: 60px;
    }

    .reassign-table-first-column {
        position: relative;
        left: -11px;
        top: 7px;
    }

    .checkbox-mob-padd {
        margin-top: 2.5rem;
    }
    .displayBlock {
        display: block;
    }
    .date-column {
        min-width: 130px !important;
    }
    .showMobile {
        display: block;
    }
    .showDesktop {
        display: none;
    }
    .admin-reassign-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 2.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        overflow: auto;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                min-width: 60px;
            }

            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

/******* Admin Search Section *******/
.agent-search-container input.form-control {
    height: 44px;
    border: 1px solid #636466;
    border-radius: 2px;
}

.agent-search-container button.btn.btn-secondary {
    height: 44px;
    border: 1px solid #636466;
    padding: 0 10px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.reassign-agent-search-heading a {
    margin-top: 0rem !important;
    color: #0071ad;
    text-decoration: underline;
    display: inline-flex;
}

.reassign-agent-search-heading img.back-arrow-icon {
    margin: 0 11px 0 0 !important;
    width: 15px !important;
    height: 14px !important;
    position: relative !important;
    top: 6px !important;
}

.agent-search-heading a {
    margin-top: 0rem !important;
    color: #0071ad;
    text-decoration: underline;
    display: inline-flex;
}

.agent-search-heading img.back-arrow-icon {
    margin: 0 11px 0 0 !important;
    width: 15px !important;
    height: 14px !important;
    position: relative !important;
    top: 6px !important;
}
div.subHeading {
    font-size: 0.75rem !important;
    color: #555;
    line-height: 1rem !important;
    padding-top: 1px;
}
