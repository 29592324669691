$position-fixed: fixed;
$position-absolute: absolute;
$position-relative: relative;
$zero: 0;
$none: none;
$block: block;
$in-block: inline-block;
$font: 1rem;
$left: left;
$right: right;
$primary-aflac-blue: #0071ad;
$primary-grey: #555;
$primary-medium-grey: #ccc;
$primary-light-grey: #f1f3f4;
$primary-white: #fff;
$primary-orange: #f89728;
$primary-torqoise: #01a7e1;
$primary-aflac-light-blue: #ecfaff;
$alert-success: #0a8145;
$alert-danger: #d13719;
$black-color: #000;
$hidden: hidden;
$gutter-space: 16;
$clear: both;
$full-percentage: 100%;
$underline: underline;
$auto: auto;
$bg-color: #0071ad;
$width: 10px;
$success-button: 1;
$danger-button: 1;

/************ MY Variables  ***************/
$flex: $flex;
$normal: 400;
$semi-bold: 600;
$bold: 700;
$inline: inline;
$opacity-half: 0.5;

a.product-link {
    padding: $zero !important;
    display: inline-block;
    border-bottom: 1px solid #0071ad;
    line-height: 1.2875rem;
    // margin-bottom: 1.425rem;
}
div.product-error {
    margin-top: 1.425rem;
}

a.product-link:hover {
    text-decoration: $none;
}

.product-anchor-head a {
    display: $block;
}
.microsite-section a {
    color: $primary-aflac-blue;
}
.aflac-group-section {
    padding: $zero;
}

.disabled-state {
    opacity: $opacity-half;
}

.middle-text {
    font-size: 0.75rem;
    color: $primary-grey;
    padding-left: 25%;
}
.product-modal {
    .form-check-label {
        width: 95% !important;
        word-break: break-all !important;
    }
}

span.form-check-label {
    line-height: $font + 0.685;
    display: $in-block;
}

.accordion-container {
    width: 39.5rem;
}

.accordion {
    font-size: $font + 0.125rem;
    border-bottom: $none;
}

.product-bdr-bott {
    border-bottom: 0.063rem solid #ccc !important;
}

.accordion-main h3 {
    color: $primary-aflac-blue;
    font-size: $font + 0.125;
    font-weight: $normal;
    border-bottom: 0.0625rem $solid $primary-aflac-blue;
    padding: $zero;
    display: $in-block;
}

.aflac-head h3 {
    font-size: $font + 0.375;
}

.aflac-head h3 a {
    color: $primary-aflac-blue;
    text-decoration: $underline;
    padding: $zero;
    margin: $zero;
    font-size: $font + 0.125;
}

h5.modal-title {
    font-size: 1.375rem;
    line-height: 1.1rem;
    padding: 0.3125rem $zero $zero $zero;
    font-weight: $semi-bold;
    position: map-get($position, "relative");
    right: 0.9375rem;
}

.product-main h2.no-line {
    border-bottom: $none;
}

.product-accordion {
    display: $block;
}

.product-accordion span img {
    float: $right;
}

.preview-popup.modal-body p a {
    display: inline-flex !important;
    color: $primary-aflac-blue;
}

.reset-btn {
    width: 11.3125rem;
    margin-right: 0.35rem;
}
.state-modal-footer {
    margin: 0 !important;
}
.cancel-btn {
    width: 102px;
}
.reset-btn:hover {
    width: 11.3125rem;
}
a.cancel-a {
    color: $primary-aflac-blue;
    text-decoration: $underline;
    padding: 0.53125rem;
}

.modal-footer.product-footer {
    border-top: $zero;
}
.modal-out-btn {
    background: $transparent;
    border: 0.125rem $solid $primary-aflac-blue !important;
    color: $primary-aflac-blue !important;
    width: 16.25rem;
    height: 3.125rem;
    margin-right: $font + 0.25;
    font-size: $font + 0.125;
    font-weight: $semi-bold;
}
.modal-out-btn:hover {
    width: 16.25rem !important;
}
.modal-out-btn:active {
    width: 16.25rem !important;
    border: 0.125rem $solid $primary-aflac-blue;
    color: $primary-aflac-blue;
    background: transparent !important;
    opacity: 0.7;
    transition: 2s;
}
.modal-out-btn:focus {
    width: 16.25rem !important;
    border: 0.125rem $solid $primary-aflac-blue;
    color: $primary-aflac-blue;
    background: transparent !important;
    opacity: 0.7;
    transition: 2s;
}

.blue-dot {
    width: 0.625rem;
    height: 0.625rem;
    background-color: $primary-torqoise;
    display: $in-block;
    border-radius: $full-percentage/2;
    position: map-get($position, "relative");
    top: 0.1875rem;
}

.no-line {
    border-bottom: $none !important;
}

.aflac-head {
    width: 39.5rem;
}

.product-modal .modal-dialog {
    width: 40.81rem !important;
    height: 32.5rem !important;
    display: $flex;
}
.product-modal .modal-content {
    padding: $font + 0.25 $font + 1.5;
    border: $zero;
    border-radius: 0.625rem;
    max-height: 90vh !important;
    @media screen and (max-width: 37.5rem) {
        min-height: 90vh !important;
    }
}

.product-modal .modal-header {
    border-bottom: 0.125rem $solid #707070 !important;
    padding: $zero !important;
}

.product-modal .modal-footer {
    margin: $zero $auto;
    padding: $zero;
}

.modal-pri-btn {
    width: 17.25rem !important;
}

.left-section h3,
.right-section h3 {
    font-size: $font + 0.125;
    color: $primary-grey;
    font-weight: $semi-bold;
}

.left-section label {
    font-size: 1.125rem;
}

.left-section {
    width: $full-percentage/2;
}
.right-section {
    width: $full-percentage/2;
}

.left-section .form-check,
.right-section .form-check {
    padding: $zero;
}

.accordion-labels label {
    font-size: $font + 0.125;
    font-weight: $semi-bold;
    color: $primary-grey;
}

/****** Edit Saved Section CSS Starts ******/
.saved-data h3 {
    font-size: $font + 0.125;
    color: $primary-grey;
    margin: $zero;
    padding: $zero;
    line-height: 1.6875rem;
}

.saved-data ul {
    list-style-type: none;
    padding: $zero;
    display: inline-block;
}

.saved-data ul li {
    font-size: $font + 0.125;
    color: $primary-grey;
    line-height: 1.6875rem;
}
span.edit-section {
    // right: 1.875rem;
    position: map-get($position, "relative");
    cursor: pointer;
    top: 0rem;
}

.edit-section a {
    float: $right;
    padding: 0.3rem 0.75rem 0.75rem 0.75rem;
    font-size: $font + 0.125;
    font-weight: $normal;
    line-height: $font + 0.25rem;
    color: $primary-aflac-blue !important;
    text-decoration: $underline !important;
}
.edit-section a:hover {
    text-decoration: $none;
}
.edit-section img {
    position: map-get($position, "relative");
    left: 0.8125rem;
    // top: -0.25rem;
    // padding: 0.625rem;
}
.product-error p {
    display: $inline;
    top: 0.1875rem;
    position: map-get($position, "relative");
    font-size: $font + 0.125;
    left: 0.5rem;
    color: $primary-grey;
}
.product-error a {
    display: $inline;
    border-bottom: 0.0625rem $solid $primary-aflac-blue;
    line-height: $font + 0.375rem;
    color: $primary-aflac-blue !important;
}
.product-error a:hover {
    border-bottom: $none;
    text-decoration: $none;
}

.accord-plus-icon {
    padding: 0.775rem $zero $zero $zero;
}
.accord-minus-icon {
    padding: 1.08rem $zero $zero $zero;
}

span.accord-heading {
    top: 0.125rem;
    position: map-get($position, "relative");
}

/******* Return to Dashboard Modal pop CSS *******/
#publish-modal .modal-content {
    width: 21.4375rem;
    padding: 0.3125rem $zero;
    margin: auto;
}
.return-btn {
    width: 18.9375rem;
}
a.btn.btn-secondary.return-btn:hover {
    width: 18.9375rem;
    background: transparent;
    border: 0.125rem $solid map-get($theme-colors, "primary");
}
a.btn.btn-secondary.return-btn:active {
    width: 18.9375rem;
    background: transparent;
    border: 0.125rem $solid map-get($theme-colors, "primary");
    opacity: 0.7;
}
a.btn.btn-secondary.return-btn:focus {
    width: 18.9375rem;
    background: transparent;
    border: 0.125rem $solid map-get($theme-colors, "primary");
}
.copy-url {
    font-size: 1.125rem !important;
    padding: 0.5rem $zero 0.5625rem $zero;
    font-weight: $bold;
    text-decoration: $none !important;
    cursor: pointer;
}
span.c-url {
    border-bottom: 0.0625rem solid map-get($theme-colors, "primary");
    margin-left: 0.3125rem;
}
section.flyer-section {
    padding-top: 12px;
}
span.c-url-download {
    margin-left: 0.3125rem;
    font-weight: 400;
    font-size: 0.875rem;
}
small.flyer-grey-txt {
    color: #555;
    line-height: 1.6875rem;
    font-size: 0.875rem;
}
.extra-padd {
    padding: 0 0 0.7625rem 0 !important;
}
.custom-control label {
    font-size: 1.125rem;
    font-weight: $normal;
    color: map-get($theme-colors, "grey");
    top: 9px;
}
p.primary-text {
    margin-bottom: 0.5rem;
}
p.modal-title.mb-2 {
    margin-bottom: $zero !important;
}
.radio-section a {
    padding: 0.4375rem 0.6875rem 0.4375rem 0.875rem;
    color: map-get($theme-colors, "primary") !important;
    cursor: pointer;
}
.radio-section a:hover {
    color: map-get($theme-colors, "primary");
    cursor: pointer;
}
.radio-section .radio a img {
    margin-left: 0.125rem;
}
.microsite-section p {
    color: $primary-grey;
}
label.radio-lbl {
    display: contents;
    font-size: 1.125rem;
    color: map-get($theme-colors, "grey");
}
.enroll-small {
    font-size: 0.75rem;
}

.btn-secondary:active,
.btn-secondary:focus {
    background: transparent !important;
    border: 0.125rem solid map-get($theme-colors, "primary") !important;
    opacity: 0.7;
    transition: 2s;
}
.btn-primary:active,
.btn-primary:focus {
    background: #00659a !important;
}
span.product-tooltip.tooltip-container {
    position: map-get($position, "absolute") !important;
    top: 4.6875rem !important;
}

/*********** Calendar Custom CSS Starts *************/
.flatpickr-calendar {
    padding: 0.3125rem $zero;
}
.flatpickr-day {
    border-radius: $zero;
    font-size: $font + 0.125;
}
.flatpickr-weekdays {
    font-size: 0.75rem;
    color: map-get($theme-colors, "grey");
    font-weight: $normal;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    border-color: map-get($theme-colors, "primary");
    background: map-get($theme-colors, "primary");
    color: #fff;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
    border-radius: $zero;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
    border-radius: $zero;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: map-get($theme-colors, "primary");
    border-color: map-get($theme-colors, "primary");
}
.flatpickr-day.inRange {
    border-radius: $zero;
    -webkit-box-shadow: -0.3125rem $zero $zero #cce3ef, 0.3125rem $zero $zero #cce3ef;
    box-shadow: -0.3125rem $zero $zero #cce3ef, 0.3125rem $zero $zero #cce3ef;
    background: #cce3ef;
    border-color: #cce3ef;
}

.flatpickr-day:hover {
    background: map-get($theme-colors, "primary");
    border-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
}

.flatpickr-current-month span.cur-month,
.flatpickr-current-month input.cur-year {
    font-size: $font + 0.125;
    font-weight: $bold;
    color: map-get($theme-colors, "grey");
}

/****** Edit Saved Section CSS Ends ******/

@media (min-width: 36rem) {
    .product-modal .modal-dialog {
        max-width: inherit;
        margin: 1.75rem auto;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-device-width: 37.5rem) {
    .modal-dialog .modal-client-logo-upload {
        max-height: 95vh;
        width: 100% !important;
        overflow: auto;
        height: 95vh;
    }

    .vue-advanced-cropper__background,
    .vue-advanced-cropper__foreground {
        background: transparent !important;
        width: auto !important;
    }

    span.btn.btn-primary.client-logo-btn {
        width: 100% !important;
        float: left;
        margin: 0 10px 20px 0px !important;
    }
    div.button-wrapper {
        span.save-btn-logo {
            margin-right: 0px !important;
            @media screen and (max-width: 37.5rem) {
                margin-bottom: 0px !important;
            }
        }
    }

    .modal-backdrop {
        display: $block !important;
        top: $zero !important;
    }
    .accordion-container {
        width: $auto;
    }
    .product-modal .modal-dialog {
        width: 21rem !important;
        height: $auto !important;
        display: $flex;
        margin: $zero $auto;
        // top: 6.25rem;
    }
    .left-section,
    .right-section {
        width: $full-percentage;
    }
    h5.modal-title {
        font-size: $font + 0.375;
        line-height: $font * 2 + 0.0625rem;
    }
    .modal-dialog.modal-screen-center {
        min-height: calc(100vh - 3.75rem);
        display: $flex;
        flex-direction: column;
        justify-content: center;
        margin: $zero $auto;
    }
    .modal-out-btn {
        margin-right: $zero;
        width: 17.25rem !important;
    }
    .form-check {
        padding: $zero;
    }
    .middle-text {
        padding-left: 7%;
    }
    .edit-section {
        float: $left !important;
        left: auto !important;
        position: absolute !important;
        cursor: pointer !important;
        top: 0rem !important;
    }
    .saved-data h3 {
        margin-top: 1rem;
    }
    .aflac-head {
        width: $full-percentage;
    }
    .product-date .input-group.date.date-field {
        width: 46%;
    }

    .flatpickr-calendar.open {
        // height: 40.125rem !important;
        // width: 100% !important;
        // left: $zero !important;
        border-radius: $zero;
        font-size: $font + 0.125 !important;
    }

    .flatpickr-days {
        height: 18rem !important;
        width: 370px !important;
    }

    .dayContainer:nth-child(1) {
        position: map-get($position, "absolute") !important;
        left: 1.375rem !important;
        top: 3.125rem !important;
        font-size: $font + 0.125;
        width: 22rem !important;
        max-width: 22rem;
    }

    .dayContainer:nth-child(2) {
        position: map-get($position, "relative") !important;
        top: 18.125rem !important;
        left: 0.5rem !important;
        font-size: $font + 1.375rem 0.125;
        box-shadow: none !important;
        width: 22rem !important;
        max-width: 22rem;
    }
    .flatpickr-weekdays {
        border-bottom: 0.0625rem solid #ccc !important;
        width: 22.5rem !important;
    }
    .flatpickr-months .flatpickr-month {
        height: 3.125rem !important;
    }
    .flatpickr-weekdaycontainer:nth-child(1) {
        top: $zero !important;
        position: map-get($position, "relative") !important;
        font-size: 0.75rem !important;
        color: map-get($theme-colors, "grey") !important;
        left: 0.5rem !important;
    }

    .flatpickr-weekdaycontainer:nth-child(2) {
        display: $none;
    }

    .flatpickr-month:nth-child(2) {
        position: map-get($position, "relative") !important;
        // top: 5.625rem !important;
        right: 1.875rem !important;
    }
    .flatpickr-month:nth-child(3) {
        position: map-get($position, "relative") !important;
        top: 23rem !important;
        right: 13.0625rem !important;
    }

    .numInputWrapper {
        position: map-get($position, "absolute") !important;
        float: $right !important;
        font-size: $font + 0.125 !important;
        font-weight: $bold !important;
        color: map-get($theme-colors, "grey");
    }
    span.cur-month {
        font-size: $font + 0.125 !important;
        font-weight: $bold !important;
        color: map-get($theme-colors, "grey") !important;
        margin-left: $zero !important;
    }
    .flatpickr-day {
        width: 20% !important;
        flex-basis: 20% !important;
        max-width: 2.875rem !important;
        height: 2.625rem !important;
        line-height: 2.4375rem !important;
    }
}

@media only screen and (min-device-width: 20rem) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .modal-dialog {
        max-width: 99vw !important;
        margin: $auto !important;
        top: 1rem;
    }

    .flatpickr-calendar.open {
        // height: 40.125rem !important;
        // width: 100% !important;
        // left: $zero !important;
        border-radius: $zero;
    }

    .flatpickr-days {
        height: 18rem !important;
        width: 370px !important;
    }

    .dayContainer:nth-child(1) {
        position: absolute !important;
        left: 0.5rem !important;
        top: 3.125rem !important;
        font-size: $font + 0.125;
        width: 352px !important;
        max-width: 352px;
    }

    .dayContainer:nth-child(2) {
        position: map-get($position, "relative") !important;
        top: 19.9375rem !important;
        left: 0.5rem !important;
        font-size: $font + 0.125;
        box-shadow: $none !important;
    }
    .flatpickr-weekdays {
        border-bottom: 0.0625rem solid #ccc !important;
    }
    .flatpickr-months .flatpickr-month {
        height: 3.125rem !important;
    }
    .flatpickr-weekdaycontainer:nth-child(1) {
        top: $zero !important;
        position: map-get($position, "relative") !important;
        font-size: 0.75rem !important;
        color: map-get($theme-colors, "grey") !important;
        left: 0.5rem !important;
    }

    .flatpickr-weekdaycontainer:nth-child(2) {
        display: $none;
    }

    .flatpickr-month:nth-child(2) {
        position: map-get($position, "relative") !important;
        // top: 5.625rem !important;
        right: 1.875rem !important;
    }
    .flatpickr-month:nth-child(3) {
        position: map-get($position, "relative") !important;
        top: 23rem !important;
        right: 13.0625rem !important;
    }

    .numInputWrapper {
        position: map-get($position, "absolute") !important;
        float: $right !important;
        font-size: $font + 0.125 !important;
        font-weight: $bold !important;
        color: map-get($theme-colors, "grey");
        top: 9px;
    }
    span.cur-month {
        font-size: $font + 0.125 !important;
        font-weight: $bold !important;
        color: map-get($theme-colors, "grey") !important;
        margin-left: $zero !important;
    }
    .flatpickr-day {
        width: 20% !important;
        flex-basis: 20% !important;
        max-width: 2.875rem !important;
        height: 2.625rem !important;
        line-height: 2.4375rem !important;
    }
}

.enrollment-head {
    font-size: 1.125rem !important;
    color: #0071ad !important;
    font-weight: 600 !important;
    padding: 0 !important;
}

/* Landscape */
@media only screen and (min-device-width: 20rem) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .modal-dialog-site {
        overflow: $auto !important;
        height: 90vh;
        border-radius: 0.25rem;
        width: 20.937rem !important;
        margin: $auto;
    }
    body .modal-dialog .modal-content .modal-body {
        /*max-height: 12.5rem;*/
        overflow: $auto;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 50rem) {
}
@media only screen and (min-width: 64rem) {
    .content-wrapper {
        margin: 8.0625rem 0 0 6.625rem;
    }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 75rem) {
}
body {
    background: transparent linear-gradient(180deg, #fff 0%, #ecfaff 100%) 0% 0% no-repeat padding-box;
}
/*
          ----------------------------------------------
                              CHECKBOX
          ----------------------------------------------
*/
/* Base for label styling */

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: $position-absolute;
    left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: map-get($position, "relative");
    padding-left: $font + 2.125 !important;
    cursor: $pointer;
    width: $auto;
    line-height: 1.4rem;
}

/* checkbox aspect */

[type="checkbox"]:checked + label:before {
    content: "";
    position: $position-absolute;
    left: $zero;
    top: $zero;
    width: $font + 0.875rem;
    height: $font + 0.875rem;
    background: $primary-aflac-blue;
    border-radius: $zero;
    line-height: 1.4rem;
}

[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: $position-absolute;
    left: $zero;
    top: $zero;
    width: $font + 0.875rem;
    height: $font + 0.875rem;
    border: 0.125rem $solid $primary-aflac-blue;
    background: $transparent;
    border-radius: $zero;
}

/* checked mark aspect */

[type="checkbox"]:checked + label:after {
    content: " ";
    position: $position-absolute;
    top: 0.6875rem !important;
    left: 0.6875rem !important;
    font-size: $font + 0.25rem;
    color: $primary-white;
    color: $primary-white;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

[type="checkbox"]:not(:checked) + label:after {
    // content: '✔';
    position: $position-absolute;
    top: 0.6875rem !important;
    left: 0.6875rem !important;
    font-size: $font + 0.25rem;
    color: $primary-white;
    color: $primary-white;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

/* checked mark aspect changes */

[type="checkbox"]:not(:checked) + label:after {
    opacity: 1;
    transform: scale(1);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* accessibility */

[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    outline: $none !important;
}

.product-anchor-head {
    a {
        word-break: break-all !important;
    }
}
.confirm-footer {
    width: 94% !important;
}
a.cancel-confirm {
    padding: 0 50px 0 0 !important;
}
.modal-body.productpopup {
    height: 250px !important;
    overflow-y: auto !important;
    overflow-x: hidden;
    margin-bottom: 1.75rem;
}
.schedule-link-text {
    display: block;
    font-size: 0.75rem;
    color: $primary-grey;
    margin-top: -8px;
    /* clear: both; */
    padding: 0;
    position: relative;
    top: -12px;
}
.schlink {
    clear: both;
    padding: 0;
    margin: 0;
}

.product-checkbox-empty {
    margin-top: 0rem;
    display: block;
    input[type="checkbox"] {
        margin-left: 1.25rem;
        @media screen and (max-width: 37.5rem) {
            margin-left: 0px;
        }
    }
}
.product-ul-empty {
    margin: 0;
}
.save-publish-btn {
    margin-bottom: 10.125rem;
    @media screen and (max-width: 37.5rem) {
        margin-bottom: 4rem;
    }
}

.product-title-label {
    // width: 65% !important;
    word-break: break-word;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
    @media screen and (max-width: 1024px) {
        min-width: 23rem !important;
    }
}

.modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0rem !important;
}
.modal-dialog-site {
    width: 343px !important;
}

.modal-dialog-site .modal-content {
    padding: 4px !important;
}
.modal-dialog.modal-dialog-site .preview-popup.modal-body {
    max-height: 350px !important;
    overflow: auto;
}

.date-validate-error {
    position: relative !important;
    top: -2px !important;
    // left: 22px;
    margin-right: 10px;
}
div.date-invalid-danger-error {
    // float: left!important;
    display: flex !important;
}
.live-date-gap {
    padding-bottom: 10px !important;
}

// Client logo upload

.client-logo-section {
    padding: 0 0 0 0;
    display: inline-block;
}
.client-logo-section h3 {
    margin: $zero;
}

.client-profile-image {
    // margin-bottom: 0.5rem;]
    position: relative;
    width: 300px;
    float: left;
    width: 100%;
}
.uploadText {
    position: absolute !important;
    right: 0px;
    top: -40px !important;
}
.client-profile-image .client-profile-image__file {
    display: none !important;
}
.client-profile-image .client-profile-image__label {
    pointer-events: all;
    position: relative;
    top: -1.5125rem;
    margin-bottom: 0;
}
.client-profile-image .client-profile-image__image {
    cursor: pointer;
    vertical-align: text-bottom;
    padding: 0.625rem 0;
    position: relative;
    top: 0.6875rem;
    left: 0;
}
.client-profile-image .client-profile-image__text {
    margin-left: 0.6rem;
    color: #0071ad;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
    border-bottom: 0.063rem solid #0071ad;
    cursor: pointer !important;
    padding-bottom: 0;
}
.client-profile-image .client-profile-image__text p {
    display: inline;
    padding: 0.6875rem 0;
    color: $primary-aflac-blue;
}

.save-image-section {
    display: $in-block;
    background: #fff;
    width: 300px;
    min-height: 105px;
    border-radius: 0.5rem;
    border: 1px solid #bebebe;
    padding-top: 16px;
    text-align: center;
}

.save-image-section ul {
    list-style-type: none;
    padding: $zero;
}

.save-image-section ul li {
    padding: 0 0 0 0;
}

span.image-txt {
    font-style: italic;
    color: $primary-grey;
    font-size: 14px;
}

.image-confirmation-section {
    width: 301px;
    padding: 3px 0 0 0;
}
.image-confirmation-section p {
    display: inline;
    color: $primary-grey;
    font-size: 14px;
}
.image-confirmation-section p a {
    font-size: 14px;
    text-decoration: underline;
}
.save-logo-container {
    margin-bottom: 1.45rem;
}
.hideSample {
    display: none;
}
.error-section-client-logo {
    color: $primary-grey;
    margin-bottom: 1rem;
    font-style: italic;
    padding-top: 5px;
}
p.remove {
    font-size: 1.125rem;
    text-align: center;
    font-weight: bold;
    color: $primary-grey;
    padding-top: 22px;
}

.modal-logo-remove-confirmation a.cancel-a {
    margin: 0 47px 0 0;
}

.modal-logo-remove-confirmation .modal-footer {
    margin: 0px 7px 0px 0;
}

.modal-logo-remove-confirmation button.close {
    padding-top: 4px !important;
    margin-top: 0px !important;
}

.client-logo-cropper.vue-advanced-cropper {
    width: 649px;
    height: 395px;
    @media screen and (max-device-width: 37.5rem) {
        height: 395px;
    }
    background: #4c4b4b;
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
    background: #4c4b4b !important;
}
h3.product-seciton {
    line-height: 0.5rem !important;
}
.vue-preview--fill {
    background: #575050 !important;
}

span.instruction-text {
    text-align: center;
    display: block;
    padding: 14px 0 21px 0;
    @media screen and (max-width: 37.5rem) {
        padding-top: 28px;
        padding-bottom: 30px;
    }
    font-size: 1.25rem;
    color: #000;
    font-weight: 600;
}
.retry-p {
    margin-bottom: 26px;
}

span.btn.btn-primary.client-logo-btn {
    width: 204px;
    float: left;
    margin: 0 17px 0px 0px;
}

img.client-logo {
    width: auto;
    height: 58px;
    max-width: 70%;
    margin: 0 auto;
}
.modal-logo-remove-confirmation {
    .modal-footer {
        margin-right: 0px !important;
        padding: 0 0.938rem 1.3rem 0.938rem !important;
    }
}

.save-logo-container p {
    font-size: 14px !important;
    cursor: pointer;
    color: #0071ad;
    text-decoration: underline;
}

.client-logo-section p {
    font-size: 1.063rem;
}
@media screen and (min-width: 48rem) {
    .details-height-section {
        width: 31.875rem;
    }
}

span.upload-change-text {
    font-size: 14px !important;
    cursor: pointer;
    color: #0071ad;
    text-decoration: underline;
}

.modal-client-logo-upload {
    height: 34.6875rem;
    width: 43rem !important;
    padding: 20px !important;
}

.modal-logo-remove-confirmation {
    height: 194px;
    border: $none;
    width: 343px;
}
.save-logo-container {
    p.image-error {
        text-decoration: none !important;
    }
}
.page-content-space {
    margin-bottom: 0px !important;
    padding: 1.4375rem 0 0.75rem !important;
}

.normal-text {
    font-style: normal !important;
}
.error-image {
    margin-top: -8px;
}

.large-sucess-hack {
    line-height: 16px;
    margin-bottom: 0;
    padding: 0 20px 20px 20px;
}
.suggestive-toggle {
    margin: 0;
    padding: 0;
    position: relative;
    top: -4px;
    label {
        font-size: 17px;
    }
}
.undoChangesPopup {
    width: 9.3125rem;
    margin-right: 0.35rem;
}
.undoChangesPopup:hover {
    width: 9.3125rem;
}

.vue-simple-handler {
    // background: #b3c5e4 !important;
    border: 1px solid #707070 !important;
}
// [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label{
//     .form-check-label{
//         padding-left: 48px!important;
//     }
// }
// ------------------------------------New css for page id-----------------
#site-management {
    .rate-sheet {
        padding: 0;
        margin: -1px 0 0 0;
    }
    .lob-GroupHeading {
        color: $primary-aflac-blue;
        font-size: 1.125rem;
        font-weight: $semi-bold;
        @media screen and (max-width: 37.5rem) {
            padding-bottom: 0.5rem !important;
        }
    }
    a.upload-link {
        padding: 6px 0 0 0;
        display: $flex;
        text-decoration: underline;
    }
    a.upload-link img {
        padding: 0 4px 0 0;
        margin: 3px 7px 0 0;
    }
    .pdf-uploadModal {
        .modal-out-btn {
            margin-right: 0.5rem;
        }
        .modal-dialog {
            @media screen and (min-width: 1024px) {
                max-width: 45.68rem !important;
                min-width: 45.68rem !important;
                height: 32.75rem !important;
            }
            @media screen and (max-width: 37.5rem) {
                height: 42.188rem !important;
                width: 21.25rem !important;
            }
        }
        .product-footer {
            button:disabled {
                background-color: #f1f3f4 !important;
                color: $primary-grey;
                font-weight: $bold;
                font-size: 0.875rem;
                float: right;
                border: none;
            }
            button {
                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 48% !important;
                }
                @media screen and (min-width: 1024px) {
                    width: 20.938rem !important;
                }
                @media screen and (max-width: 37.5rem) {
                    width: 100% !important;
                }
                position: relative;
            }

            button.outline {
                @media screen and (max-width: 37.5rem) {
                    margin-bottom: 1rem !important;
                }
            }
        }
        .modal-content {
            padding: 1.25rem !important;
            height: 21.562rem;
            @media screen and (max-width: 37.5rem) {
                height: auto;
            }
            button.close {
                img {
                    @media screen and (max-width: 37.5rem) {
                        top: 20px;
                    }
                    top: 17px;
                    position: absolute;
                    padding: 0;
                }
            }
            .modal-header {
                border-bottom: 1px solid $primary-grey !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            .modal-title {
                font-size: 1.375rem !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            h5.heading {
                font-size: 0.875rem;
                color: $primary-grey;
                font-weight: $bold;
                padding-top: 2.125rem;
                padding-bottom: 1.25rem;
            }
            .modal-dialog {
                @media screen and (min-width: 1024px) {
                    top: 0px !important;
                    transform: translate(-50%, 5%) !important;
                }
            }
            .modal-body {
                @media only screen and (min-device-width: 20rem) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    max-height: none;
                }
            }
            .content-section {
                padding-top: 2.343rem;
                p {
                    font-size: 1.125rem;
                    color: $primary-grey;
                    font-weight: 600;
                }
            }
            .poduct-footer {
                .pdfupload {
                    color: $primary-grey !important;
                }
            }
            label.pdfuplaod {
                font-size: 1.125rem;
                cursor: default !important;
            }
        }
    }

    // ------------------------------Css for upload rate sheet disclamer

    .pdf-RateSheetModal {
        .modal-out-btn {
            margin-right: 0.5rem;
        }
        .modal-dialog {
            @media screen and (min-width: 1024px) {
                max-width: 45.68rem !important;
                min-width: 45.68rem !important;
                height: 32.75rem !important;
            }
            @media screen and (max-width: 37.5rem) {
                height: 42.188rem !important;
                width: 21.25rem !important;
            }
        }
        .product-footer {
            button:disabled {
                background-color: #f1f3f4 !important;
                color: $primary-grey;
                font-weight: $bold;
                font-size: 0.875rem;
                float: right;
                border: none;
            }
            button {
                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 48% !important;
                }
                @media screen and (min-width: 1024px) {
                    width: 20.938rem !important;
                }
                @media screen and (max-width: 37.5rem) {
                    width: 100% !important;
                }
                position: relative;
            }

            button.outline {
                @media screen and (max-width: 37.5rem) {
                    margin-bottom: 1rem !important;
                }
            }
        }
        .modal-body {
            overflow: auto;
            @media screen and (max-device-width: 768px) {
                max-height: 20rem;
            }
        }
        .modal-content {
            padding: 1.25rem !important;
            height: 22.3rem;
            @media screen and (max-width: 37.5rem) {
                height: auto;
            }
            button.close {
                img {
                    @media screen and (max-width: 37.5rem) {
                        top: 20px;
                    }
                    top: 17px;
                    position: absolute;
                    padding: 0;
                }
            }
            .modal-header {
                border-bottom: 1px solid $primary-grey !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            .modal-title {
                font-size: 1.375rem !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            h5.heading {
                font-size: 0.875rem;
                color: $primary-grey;
                font-weight: $bold;
                padding-top: 2.125rem;
                padding-bottom: 1.25rem;
            }
            .modal-dialog {
                @media screen and (min-width: 1024px) {
                    top: 0px !important;
                    transform: translate(-50%, 5%) !important;
                }
            }
            .modal-body {
                @media only screen and (min-device-width: 20rem) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    max-height: none;
                }
            }
            .content-section {
                padding-top: 2.343rem;
                p {
                    font-size: 1.125rem;
                    color: $primary-grey;
                    font-weight: 600;
                }
            }
            .poduct-footer {
                .pdfupload {
                    color: $primary-grey !important;
                }
            }
            label.pdfuplaod {
                font-size: 1.125rem;
                cursor: default !important;
            }
        }
    }
    // ----------------------------------- Css for upload rate sheet disclamer
    //---------------------------css for upload one-disclaimer popup
    .one-disclaimer {
        .modal-out-btn {
            margin-right: 0.5rem;
        }
        .modal-dialog {
            @media screen and (min-width: 1024px) {
                // max-width: 45.68rem !important;
                // min-width: 45.68rem !important;
                // height: 32.75rem !important;
                max-width: 40rem !important;
                height: 32.5rem !important;
            }
            @media screen and (max-width: 37.5rem) {
                height: 42.188rem !important;
                width: 21.25rem !important;
            }
        }
        .product-footer {
            button:disabled {
                background-color: #f1f3f4 !important;
                color: $primary-grey;
                font-weight: $bold;
                font-size: 0.875rem;
                // float: right;
                border: none;
            }
            button {
                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 48% !important;
                }
                @media screen and (min-width: 1024px) {
                    width: 7.938rem !important;
                    height: 40px;
                    line-height: 1.75rem !important;
                }
                @media screen and (max-width: 37.5rem) {
                    width: 45% !important;
                }
                position: relative;
            }

            button.outline {
                @media screen and (max-width: 37.5rem) {
                    margin-bottom: 1rem !important;
                }
            }
        }
        .modal-body {
            overflow: auto;
            @media screen and (max-device-width: 768px) {
                max-height: 20rem;
            }
        }
        .modal-content {
            padding: 1.25rem !important;
            height: 100%;
            @media screen and (max-width: 37.5rem) {
                height: auto;
            }
            button.close {
                img {
                    @media screen and (max-width: 37.5rem) {
                        top: 20px;
                    }
                    top: 17px;
                    position: absolute;
                    padding: 0;
                }
            }
            .modal-header {
                border-bottom: 1px solid $primary-grey !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            .modal-title {
                font-size: 1.375rem !important;
                @media screen and (max-width: 37.5rem) {
                    padding-top: 0rem !important;
                }
            }
            h5.heading {
                font-size: 0.875rem;
                color: $primary-grey;
                font-weight: $bold;
                padding-top: 2.125rem;
                padding-bottom: 1.25rem;
            }
            .modal-dialog {
                @media screen and (min-width: 1024px) {
                    top: 0px !important;
                    transform: translate(-50%, 5%) !important;
                }
            }
            .modal-body {
                @media only screen and (min-device-width: 20rem) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    max-height: none;
                }
            }
            .content-section {
                padding-top: 2.343rem;
                p {
                    font-size: 1rem;
                    color: $primary-grey;
                    // font-weight: 600;
                }
            }
            .poduct-footer {
                .pdfupload {
                    color: $primary-grey !important;
                }
            }
            label.disclaimer-level {
                font-size: 1.125rem;
                cursor: default !important;
            }
        }
    }

    // ------------------------css for upload one-disclaimer popup

    .pdf-confirmModal {
        @media screen and (max-width: 37.5rem) {
            .modal-dialog {
                .modal-content {
                    min-height: auto !important;
                    .modal-header {
                        .modal-ttitle {
                            font-size: 1.125rem;
                        }
                    }
                    .modal-body {
                        p.confirm-content {
                            padding: 1rem 1.125rem;
                        }
                    }
                    .product-footer {
                        button {
                            width: 100% !important;
                        }
                        .button:nth-of-type(2) {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        .modal-dialog {
            min-width: 35.5rem;
            height: 18.625rem;
            @media screen and (max-width: 37.5rem) {
                height: 21rem;
                min-width: 21rem;
            }
        }
        .modal-content {
            height: 100%;
            width: 100%;
            padding: 1.25rem;
            .modal-header {
                border-bottom: none !important;
                button.close {
                    padding-top: 0px;
                    padding-bottom: 18px;
                    margin-right: -20px;
                }
            }
            .modal-body {
                p.confirm-content {
                    @media screen and (min-width: 37.5rem) {
                        width: 355px;
                    }
                    margin: auto;
                    font-size: 18px;
                    text-align: center;
                    color: $primary-grey;
                    line-height: 22px;
                    padding-bottom: 41px;
                    padding-top: 1rem;
                }
                p.confirm-msg {
                    width: 284px;
                    margin: auto;
                    color: $primary-grey;
                    font-weight: 600;
                    text-align: center;
                }
            }
            .product-footer {
                button {
                    @media screen and (min-width: 37.5rem) {
                        width: 254px !important;
                        margin-right: 2px !important;
                        margin-bottom: 0px !important;
                    }
                }
                button:nth-of-type(2) {
                    @media screen and (min-width: 37.5rem) {
                        float: right;
                    }
                }
            }
        }
    }

    // -----------------------------Remove confirmation popup for rate sheet-------------------------
    .pdf-RemoveconfirmModal {
        @media screen and (max-width: 37.5rem) {
            .modal-dialog {
                .modal-content {
                    min-height: auto !important;
                    .modal-header {
                        .modal-ttitle {
                            font-size: 1.125rem;
                        }
                    }
                    .modal-body {
                        p.confirm-content {
                            padding: 1rem 1.125rem;
                        }
                    }
                    .product-footer {
                        button {
                            width: 100% !important;
                        }
                        .button:nth-of-type(2) {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        .modal-dialog {
            min-width: 35.5rem;
            height: 18.625rem;
            @media screen and (max-width: 37.5rem) {
                height: 21rem;
                min-width: 21rem;
            }
        }
        .modal-content {
            height: 100%;
            width: 100%;
            padding: 1.25rem;
            .modal-header {
                border-bottom: none !important;
                button.close {
                    padding-top: 0px;
                    padding-bottom: 18px;
                    margin-right: -20px;
                }
            }
            .modal-body {
                p.confirm-content {
                    @media screen and (min-width: 37.5rem) {
                        width: 355px;
                    }
                    margin: auto;
                    font-size: 18px;
                    text-align: center;
                    color: $primary-grey;
                    line-height: 22px;
                    padding-bottom: 41px;
                    padding-top: 1rem;
                }
                p.confirm-msg {
                    width: 284px;
                    margin: auto;
                    color: $primary-grey;
                    font-weight: 600;
                    text-align: center;
                }
            }
            .product-footer {
                button {
                    @media screen and (min-width: 37.5rem) {
                        width: 254px !important;
                        margin-right: 2px !important;
                        margin-bottom: 0px !important;
                    }
                }
                button:nth-of-type(2) {
                    @media screen and (min-width: 37.5rem) {
                        float: right;
                    }
                }
            }
        }
    }
    // -----------------------------Remove confirmation popup for rate sheet-------------------------
    .pdf-remove-confirmModal {
        @media screen and (max-width: 37.5rem) {
            .modal-dialog {
                // height: 19.375rem!important;
                width: 21rem !important;
                .modal-content {
                    // padding: 1.125rem;
                    // height: 100%;

                    .modal-header {
                        .modal-ttitle {
                            font-size: 1.125rem;
                        }
                    }
                    .modal-body {
                        p.confirm-content {
                            padding-bottom: 1.125rem;
                        }
                    }
                    .product-footer {
                        button {
                            width: 100% !important;
                        }
                        .button:nth-of-type(2) {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        .modal-dialog {
            height: 19.375rem;
            width: 35rem;
            .modal-content {
                padding: 1.25rem;
                height: 100%;
            }
            .modal-header {
                border-bottom: none !important;
                button.close {
                    padding-top: 0px;
                    padding-bottom: 18px;
                    margin-right: -20px;
                }
            }
            .modal-body {
                p.confirm-content {
                    @media screen and (min-width: 37.5rem) {
                        width: 355px;
                    }
                    // width: 355px;
                    margin: auto;
                    font-size: 18px;
                    padding-top: 2rem;
                    text-align: center;
                    color: $primary-grey;
                    line-height: 22px;
                    padding-bottom: 39px;
                }
                p.confirm-msg {
                    width: 284px;
                    margin: auto;
                    color: $primary-grey;
                    font-weight: 600;
                    text-align: center;
                    @media screen and (max-width: 37.5rem) {
                        padding-top: 2rem;
                    }
                    padding-top: 4rem;
                }
            }
            .product-footer {
                button {
                    @media screen and (min-width: 37.5rem) {
                        width: 13.75rem !important;
                        margin-right: 2px !important;
                        margin-bottom: 0px !important;
                    }
                }
                button:nth-of-type(2) {
                    @media screen and (min-width: 37.5rem) {
                        float: right;
                    }
                }
            }
        }
    }

    .rate-success-message {
        padding-left: 0rem;
        @media (min-device-width: 64rem) {
            padding-left: 1.3rem;
        }
    }
    .save-logo-tiles {
        // margin-bottom:2.9rem!important
        @media screen and (min-device-width: 37.5rem) {
            margin-left: 1.8rem;
            margin-bottom: 1.25rem !important;
            padding-top: 0.4rem;
        }

        .save-image-section {
            padding-bottom: 1px;
            width: 20.875rem;
            border-radius: 0.375rem !important;
            @media screen and (min-device-width: 37.5rem) {
                width: 20.437rem;
            }
            min-height: 3.625rem;
            padding-top: 1.125rem;
        }
        .image-confirmation-section {
            width: 326px !important;
        }
    }

    .non-aflac-dropdown {
        padding: 0.625rem 0 1.25rem 0;
    }
    select.Non-Aflac-dropdown {
        padding: 10px 20px;
        word-wrap: normal;
        background: #0071ad;
        color: #ffffffc4;
        border: none;
        option {
            background: #fff;
            color: #000;
            width: 100%;
            padding: 20px;
            line-height: 50px;
        }
    }

    .non-aflac-logo-tiles {
        // margin-bottom:2.9rem!important
        @media screen and (min-device-width: 37.5rem) {
            margin-left: 0rem;
            margin-bottom: 1rem !important;
        }

        .save-image-section {
            padding-bottom: 1px;
            width: 20.875rem;
            border-radius: 0.375rem !important;
            @media screen and (min-device-width: 37.5rem) {
                width: 20.437rem;
            }
            min-height: 3.625rem;
            padding-top: 1.125rem;
        }
        .image-confirmation-section {
            width: 326px !important;
        }
    }

    .Aflac-Individual-checkbox {
        @media screen and (min-device-width: 37.5rem) {
            margin-left: 3.125rem;
            margin-top: 16px;
        }
        #custom-select-individual {
            position: relative;
            z-index: 3;
        }
    }
    .Aflac-Group-checkbox {
        @media screen and (min-device-width: 37.5rem) {
            margin-left: 3.125rem;
            margin-top: 7px;
        }
    }
    .highlight-section {
        display: block;
        font-size: 0.75rem;
        color: $primary-grey;
        width: 101%;
    }
    [type="checkbox"]:disabled::before {
        border: #a0a0a0;
        cursor: not-allowed;
    }
    [type="checkbox"]:disabled + label {
        color: #a0a0a0;
        cursor: not-allowed;
    }
    [type="checkbox"]:disabled + label::before {
        border: 2px solid #a0a0a0;
        cursor: not-allowed;
    }
    @media screen and (max-width: 37.5rem) {
        .pl-4 {
            padding-left: 0 !important;
        }
    }
    [type="radio"]:not(:checked) + label:before {
        left: 2px !important;
    }
    .Schedule-option {
        margin-bottom: 0px;
        font-weight: 600;
        padding-bottom: 0px;
        color: $primary-grey;
        @media screen and (max-width: 37.5rem) {
            padding-top: 1.25rem;
            padding-bottom: 0.313rem;
        }
    }
    .Schedule-option-level {
        font-size: 1.125rem;
        color: $primary-grey;
        padding-bottom: 0.75rem;
        @media screen and (max-width: 37.5rem) {
            padding-bottom: 1.08rem;
        }
        width: 100%;
    }
    .message-content {
        color: $primary-grey;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 37.5rem) {
            margin-bottom: 0.4rem;
        }
    }
    .footer-note {
        max-width: 39.5rem;
        text-align: justify;
        color: $primary-grey;
        font-size: 1.125rem;
        /* opacity: 0.8; */
        margin-top: 1.65rem;
    }
    .contentMsg {
        font-size: 0.75rem;
        color: $primary-grey;
    }
    .lbldisabled {
        opacity: 0.5;
        pointer-events: none;
    }
    .heading-top {
        font-size: 1.125rem;
        color: $primary-grey;
    }
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: 2px;
        height: 30px;
        width: 30px;
        // top: 89px;
        top: 6px;
        z-index: 9;
        cursor: pointer;
        opacity: 0;
    }
    .parent-position {
        position: relative;
    }
    .upload-btn-continue {
        position: absolute;
        left: 0;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
    .modal-content {
        border-radius: 0.625rem !important;
    }
    .non-sub-head {
        display: $flex;
        text-decoration: underline;
        clear: both;
        img {
            width: 23px;
            height: 23px;
            margin-right: 10px;
            margin-top: 2px;
        }
    }
    .lob-subHeading {
        font-size: 1.063rem;
        color: $primary-grey;
        margin-bottom: 1.25rem;
        @media screen and (max-width: 37.5rem) {
            margin-bottom: 1rem;
        }
    }
    .non-aflac-section .lob-subHeading {
        margin-bottom: 1rem;
        clear: both;
        .retry-p {
            margin-bottom: 0px !important;
        }
    }
    .product-border-bottom {
        border-bottom: 1px solid #ccc;
    }

    .Aflac-Group-checkbox-suggestive {
        margin-left: 3.125rem;
        clear: both;
    }
    .closeIcon {
        position: absolute;
        right: 3px;
        top: 13px;
        padding: 10p;
        color: #979191;
        font-size: 14px;
        font-weight: bold;
    }
    .suggestive-msg {
        max-width: 31.5625rem;
        padding-bottom: 1.25rem;
        @media screen and (max-width: 37.5rem) {
            padding-bottom: 1rem;
        }
    }
    .hidefileupload {
        display: none;
    }
    .group-label {
        padding-top: 13px;
        color: #555;
    }
    .save-btn-logo {
        margin-bottom: 0px !important;
    }
}

#site-management .Aflac-Group-checkbox-suggestive [type="checkbox"]:not(:checked),
#site-management .Aflac-Group-checkbox-suggestive [type="checkbox"]:checked {
    top: 16px;
}

#site-management .Aflac-Indiv-checkbox-suggestive [type="checkbox"]:not(:checked),
#site-management .Aflac-Indiv-checkbox-suggestive [type="checkbox"]:checked {
    top: 16px !important;
}
#vs1__listbox {
    z-index: 9999 !important;
}
.vs__selected-options {
    z-index: 0;
}
.Aflac-Group,
.Aflac-Plad {
    label {
        font-size: 1.125rem;
    }
}

.validation-message {
    display: inline-block;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: #d13719;
    padding: 0;
    margin: 0px;
    top: -4px;
    position: relative;
    @media screen and (max-width: 37.5rem) {
        width: 90%;
    }
}
.product-error-icon {
    position: relative;
    left: 8px;
    float: right;
    top: -6px;
    @media screen and (max-width: 37.5rem) {
        top: -16px;
    }
}

.pdf-uploadModal .modal-body {
    height: 250px !important;
    @media screen and (max-width: 37.5rem) {
        height: 190px !important;
    }
    overflow-y: auto !important;
    overflow-x: hidden;
    margin-bottom: 1rem;
}
label.product-title-label.form-check-label {
    width: 85% !important;
}
span.edit-section {
    position: relative;
    cursor: pointer;
    top: 7px;
}

/* ----------- iPhone X ----------- */
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    .modal-client-logo-upload .modal-body {
        max-height: 28.5rem !important;
        overflow: auto !important;
        max-width: 625px !important;
    }
    span.btn.btn-primary.client-logo-btn {
        width: 196px !important;
        float: left !important;
    }
    .modal-client-logo-upload {
        height: 34.6875rem !important;
        width: 43rem !important;
        padding: 15px !important;
        margin-bottom: 40px !important;
    }
    span.instruction-text {
        padding: 8px 0 8px 0;
    }
    #site-management .pdf-uploadModal .modal-content {
        height: 400px;
    }

    #site-management .pdf-uploadModal .product-footer button {
        width: 48% !important;
    }

    .accord-plus-icon {
        position: relative;
        right: 10px;
    }
    .accord-minus-icon {
        position: relative;
        right: 10px;
    }
    .aflac-head h3 {
        padding: 0 11px 0 0;
    }
    #site-management .footer-note {
        padding-right: 11px;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
    .modal-client-logo-upload .modal-body {
        max-height: 28.5rem !important;
        overflow: auto !important;
        max-width: 625px !important;
    }
    span.btn.btn-primary.client-logo-btn {
        width: 196px !important;
        float: left !important;
    }
    .modal-client-logo-upload {
        height: 34.6875rem !important;
        width: 43rem !important;
        padding: 15px !important;
        margin-bottom: 20px !important;
    }
    span.instruction-text {
        padding: 8px 0 8px 0;
    }

    #clientLogo-modal .modal-dialog {
        max-width: 575px !important;
    }
    #site-management .pdf-uploadModal .modal-content {
        height: 400px;
    }

    #site-management .pdf-uploadModal .product-footer button {
        width: 48% !important;
    }
}

.non-aflac-dropdown .dropdown {
    // min-height: 550px;
    display: inline-block;
    margin: 0 0 0 20px;
    overflow: visible;
    width: 315px;
    @media screen and (max-width: 768px) {
        margin: $zero;
        width: $full-percentage;
    }
}
#non-Aflac-message {
    margin-bottom: 0.5rem !important;
}

.non-aflac-dropdown .btn.btn-primary.dropdown-toggle {
    padding: 0 0 0 27px;
    text-align: left;
    @media screen and (max-width: 37.5rem) {
        max-width: 23.938rem !important;
    }
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
        width: 100% !important;
    }
}

.non-aflac-dropdown .dropdown-toggle::after {
    float: right !important;
    margin: 20px 26px 0 0;
}
.non-aflac-dropdown ul.dropdown-menu.show {
    width: 100%;
    border: 0;
    border-radius: 0;
    box-shadow: 6px 3px 8px -4px #ccc, -7px 0 4px -6px #ccc;
    transform: none !important;
    top: auto !important;
    max-height: 400px;
    overflow-y: auto;
}

.non-aflac-dropdown ul.dropdown-menu.show li a {
    font-size: 18px;
    color: #555;
    padding: 0px 20px 0px 26px;
    font-weight: 400;
    line-height: 40px;
    display: block;
}

.non-aflac-dropdown ul.dropdown-menu.show li a:hover {
    background: #eaf7fb;
    display: block;
    text-decoration: none;
    padding: 0px 20px 0px 26px;
}
.non-aflac-accordion {
    overflow: inherit !important;
}

.rate-disabled {
    span {
        color: #abaaaa !important;
        opacity: 0.5;
        pointer-events: none !important;
        font-weight: 400 !important;
        a {
            color: #555 !important;
            opacity: 0.5;
            pointer-events: none !important;
        }
    }
}

.rateIcon {
    width: 24px;
    height: 20px;
}

input[type="file"] {
    cursor: pointer;
}
.lobIcon {
    height: 35px;
    width: 39px;
}
.accordion-container button.accordion {
    padding: 0.9rem 0 !important;
}
.nonaflac-rate-disabled {
    a {
        color: #abaaaa !important;
        opacity: 0.5;
        pointer-events: none !important;
        font-weight: 400 !important;
        span {
            color: #555 !important;
            opacity: 0.5;
            pointer-events: none !important;
        }
    }
}
#agreementLink {
    color: #0071ad;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
    border-bottom: 0.063rem solid #0071ad;
    cursor: pointer !important;
    padding-bottom: 0;
    text-decoration: none;
}
.save-publish-btn:disabled {
    background-color: #f1f3f4 !important;
    color: #555;
    border-color: #cac8c8;
}
.clearfix {
    clear: both !important;
}
.pld-0 {
    margin-top: -11px;
}
.dvPlad .plp-1 {
    padding-left: 1.25rem !important;
    .loader-animation-microsite {
        width: 80px;
        position: relative;
        // top: 12px;
        // @media only screen and (min-width: 50rem) {
        //     top: 12px;
        // }
    }
}
.loader-align-center {
    display: flex;
    justify-content: center;
}

.class-disabled {
    label {
        color: #abaaaa !important;

        pointer-events: none !important;
    }
    [type="checkbox"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 1px;
        top: 7px;
        width: 30px;
        height: 30px;
        border: 2px solid #989da3 !important;
        background: transparent;
        border-radius: 0;
        cursor: none;
        pointer-events: none;
    }
}
.class-disabled [type="checkbox"]:not(:checked),
.class-disabled [type="checkbox"]:checked {
    pointer-events: none;
}
span.error-icon-left-fix img {
    left: -1.2rem;
}
section.content-section.pdfConfirm {
    padding-bottom: 0px !important;
    padding-top: 10px !important;
}
