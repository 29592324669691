@font-face {
    font-family: "ProximaNova";
    src: url("../assets/fonts/ProximaNova-Regular.otf") format("opentype");
}

$font-stack: "ProximaNova";

html,
body {
    font-family: $font-stack !important;
    font-size: $font;
    height: $percentage * 10;
}

p {
    font-size: $font + 0.125rem;
}

a {
    font-size: $font + 0.125rem;
    cursor: pointer !important;
}

*,
*:before,
*:after {
    @include box-sizing(border-box);
}

[v-cloak] > *,
[v-cloak] {
    display: map-get($display, "none");
}

.mt-20 {
    @include space(margin, top, $smallqtr);
}

.bg-aflac {
    background: transparent
        linear-gradient(180deg, map-get($theme-colors, "white") 0%, map-get($theme-colors, "light-blue") 100%) 0% 0%
        no-repeat padding-box;
    background-color: map-get($theme-colors, "light-blue");
}

.bg-solid {
    background-color: map-get($theme-colors, "primary");
    height: 100% !important;
    width: 100% !important;
}

.clear {
    clear: $clear;
}

@media screen and (max-width: 37.5rem) {
    // .main-container a {
    //     overflow: $hidden;
    //     text-overflow: $ellipsis;
    //     white-space: $nowrap;
    // }

    .acccount-content a {
        overflow: hidden;
        text-overflow: unset;
        white-space: normal;
    }
}

a {
    color: map-get($theme-colors, "primary");
}

/*Change Background color in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s;
}
