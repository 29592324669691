.dashboard-heading {
    padding-bottom: 2.875rem;
    h1 {
        width: $percentage * 10;
    }
    a {
        @include space(margin, top, $mediumhlf);
    }
}

.disable-icon {
    border-bottom: 0.125rem solid map-get($theme-colors, "medium-grey");
    width: 0.75rem;
    height: 0.125rem;
    display: map-get($display, "inline-block");
}
.account-data-table img {
    cursor: pointer;
}

.success-icon {
    //@include space(padding, top, $half);
    display: map-get($display, "inline-block");
    @media screen and (max-width: 37.5rem) {
        position: absolute;
        top: 10px;
    }
}

.account-minus {
    position: absolute;
    top: 5px;
    right: 0px;
}

.font-bold {
    font-weight: bold;
}

.date-50 {
    width: 3rem;
    float: $left;
    position: map-get($position, "relative");
    text-align: left !important;
    @include space(margin, right, $small);
}

.date-bold {
    font-weight: bold;
    font-size: 0.875rem;
    display: map-get($display, "block");
}

.date-year,
.first-end-date {
    display: map-get($display, "block");
    font-size: 0.75rem;
    padding: 0 0 0 0.6rem !important;
    @media screen and (max-width: 37.5rem) {
        padding: 0 0 0 1.3rem !important;
        text-align: right !important;
    }
    text-align: left !important;
    width: 2.56rem;
    font-weight: $normal;
}

.date-sign {
    font-weight: bold;
    position: map-get($position, "absolute");
    right: -0.375rem !important;
    @media only screen and (max-width: 37.5rem) {
        right: -0.875rem !important;
    }
    top: -0.25rem;
}

span.first-end-date {
    padding-left: 0.7rem !important;
}

@media only screen and (min-width: 50rem) {
    .dashboard-heading {
        h1 {
            width: calc(100% - 10rem);
        }
        a {
            @include space(margin, top, $half);
            // @include space(margin, bottom, $size-two + 0.875);
        }
    }
    .account-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 2.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
            }
            th:first-child {
                width: $percentage * 10/5 + 3%;
            }
            th:nth-child(2) {
                width: 5.3rem;
            }
            th:nth-child(3) {
                width: 8.5rem;
            }

            th:nth-child(4) {
                width: 5.7rem !important;
            }

            th:nth-child(5) {
                width: 3.9rem;
            }
            th:nth-child(6),
            th.text-center {
                width: 3.9rem;
            }
            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 0.65rem 0 0.9rem 0;
            }
        }
    }
}

.accord-bdr button {
    border-bottom: 0.063rem solid #ccc;
    position: relative;
    padding: 10px 0 14px 0;
}

.sorting-arrow-down {
    background: url(../assets/images/account-arrow-down.png) 1px 4px no-repeat;
    display: map-get($display, "inline-block");
    width: 20px;
    cursor: pointer;
}

.sorting-arrow-up {
    background: url(../assets/images/account-arrow-up.png) 1px 4px no-repeat;
    display: map-get($display, "inline-block");
    width: 20px;
    cursor: pointer;
}

span.account-subtxt {
    font-size: 0.75rem;
    display: block;
    color: #555;
    font-weight: 400;
    line-height: 9px;
}

.account-plusbtn {
    float: right;
    position: absolute;
    right: 10px;
    top: 15px;
}
.account-data-table img.tickImg {
    cursor: text !important;
}

.delete-cursor {
    vertical-align: -9px !important;
    font-size: 22px !important;
    cursor: pointer !important;
}

.delete-failure-msg {
    font-size: 14px;
    color: #dc3545;
}
.delete-success-msg {
    font-size: 14px;
    color: #28a745;
}
.delete-progress-msg {
    font-size: 14px;
    color: #0071ad;
}
