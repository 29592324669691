.enrollment-container {
    h2 {
        @include font(map-get($theme-colors, "white"), $size-two + 0.188, $bold, $size-two + 0.625);
        padding: $zero $zero $width * 4;
        float: $left;
        width: 100%;
        margin: 0 $auto;
    }
}

.enrollment-container h2 {
    color: #fff;
    font-size: 2.188rem;
    font-weight: 700;
    line-height: 2.625rem;
    padding: 33px 0 7px;
    float: left;
    width: 100%;
    margin: 0 auto;
}

p.access-conent {
    @include font(map-get($theme-colors, "white"), $size-one + 0.125, $normal, $font + 0.75);
    margin: $zero 1.25rem;
}

.access-conent a {
    color: #fff;
    text-decoration: underline;
}

.enrollment-expire {
    width: auto;
    display: table;
    margin: 0 auto;
    padding: 2.5rem 0 1.3rem 0;
}

.enrollment-expire ul {
    list-style-type: none;
    display: flex;
    padding: 0;
}

.enrollment-expire ul a {
    color: #fff;
}

.enrollment-expire ul li:first-child {
    padding-right: 2rem;
}

.enrollment-expire ul li {
    text-align: center;
}

.enrollment-expire ul li :nth-child(1) {
    margin: auto;
}

img.profile-pic {
    width: 3.5625rem;
    height: 3.5625rem;
    border-radius: 50%;
}

.enrollment-expire h4 {
    font-size: 1.125rem;
    color: #fff;
    font-weight: $normal;
    padding-top: 0.5rem;
}

.enrollment-expire h5 {
    font-size: 1.375rem;
    color: #fff;
    font-weight: $normal;
    padding-bottom: 0.8rem;
}

.enrollment-foot-text {
    width: 645px;
    margin: $zero $auto;
}

@media only screen and (max-width: 50rem) {
    .econtentSpanish {
        overflow: scroll !important;
    }
    p.access-conent {
        line-height: 1.65rem;

        margin-top: -23px;
    }
    .enrollment-expire h5 {
        margin-top: -8px !important;
    }
    .enrollment-container h2 {
        padding: 0px 0 7px;
    }
    .enrollment-expire {
        padding: 0 !important;
    }
    .text-mobile-left {
        text-align: $left !important;
    }

    .enrollment-expire ul {
        display: block;
    }

    .enrollment-foot-text {
        width: $auto;
        margin: $zero $auto;
        background-color: #0071ad;
    }

    .enrollment-expire ul li:first-child {
        padding-right: $zero;
    }
    .dvmainContent {
        margin-top: 72px;
    }
    section.enrollment-container.row .selectWrapper {
        border: 1.4px solid #ffffff;
        position: fixed !important;
        right: 24px !important;
        top: 14px !important;
        z-index: 9999999;
    }
}
section.enrollment-container.row .selectWrapper {
    border: 1.4px solid #ffffff;
    position: absolute;
    right: 40px;
    top: 21px;
}
section.enrollment-container.row .selectWrapper .vs__selected {
    font-weight: bold;
    color: #ececec !important;
}
section.enrollment-container.row .selectWrapper .vs__actions {
    border-bottom: 0.063rem solid #0d719f !important;
}
section.enrollment-container.row .selectWrapper .vs__open-indicator {
    fill: #ffffff !important;
    position: relative;
    top: 0.15rem;
}
.econtentSpanish .enrollment-container h2 {
    padding: 10px 0px 0px;
}
.econtentSpanish .enrollment-expire {
    padding: 0.5rem 0 1.3rem 0;
}
.clsContentClose {
    margin-top: 10px !important;
}
