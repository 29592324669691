.vs--searchable .vs__dropdown-toggle {
    @include border($zero $solid, map-get($theme-colors, "light-grey"), "");
}

.vs__selected-options {
    @include space(padding, all, $zero);
}

.vs__actions {
    @include border(0.063rem $solid, map-get($theme-colors, "medium-grey"), bottom);
}

.vs__open-indicator {
    fill: map-get($theme-colors, "grey");
    position: map-get($position, "relative");
    top: 0.25rem;
}

.vs__selected {
    left: -0.438rem;
    position: map-get($position, "absolute");
    top: 0.75rem;
}

.vs__dropdown-menu {
    background-color: map-get($theme-colors, "light-blue");
}
