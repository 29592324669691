.agent-search-heading {
    padding-bottom: 2.875rem;
    h1 {
        width: $percentage * 10;
        display: block;
    }
    a {
        @include space(margin, top, $mediumhlf);
        color: #0071ad;
    }
}

.disable-icon {
    border-bottom: 0.125rem solid map-get($theme-colors, "medium-grey");
    width: 0.75rem;
    height: 0.125rem;
    display: map-get($display, "inline-block");
}

.success-icon {
    display: map-get($display, "inline-block");
}

.font-bold {
    font-weight: bold;
}

.date-50 {
    width: 3rem;
    float: $left;
    position: map-get($position, "relative");
    text-align: right;
    @include space(margin, right, $small);
}

.date-bold {
    font-weight: bold;
    font-size: 0.875rem;
    display: map-get($display, "block");
}

.date-year,
.first-end-date {
    display: map-get($display, "block");
    font-size: 0.75rem;
    padding: 0 0 0 1rem;
}

.date-sign {
    font-weight: bold;
    position: map-get($position, "absolute");
    right: -0.875rem;
    top: -0.25rem;
}

.reassign-btn-section {
    width: 220px;
    float: right;
    :disabled {
        color: #171717;
        background-color: #ced2d7;
        border-color: #ced2d7;
        font-weight: bold;
    }
}

.reassign-search-container {
    display: inline-block;
}

.reassign-top {
    margin-top: 0px;
}

.reassign-btn-section button.btn.btn-primary {
    padding: 0 1rem;
    margin-bottom: 1px;
}

.termed-first {
    width: 210px;
}

.termed-reassigned {
    width: 210px;
}

.select-all-section label {
    color: map-get($theme-colors, "grey");
}

.mar-right {
    margin-right: 67px !important;
}

// Large screen Desktop Ipad and large
@media only screen and (min-width: 50rem) {
    .checkbox-section {
        width: 50px;
        float: left;
        margin: 48px 0 0 0;
    }

    .checkbox-section.showdesktop-checkbox {
        display: block;
    }

    .checkbox-section.showmobile-checkbox {
        display: none;
    }

    .showMobile {
        display: none;
    }
    .showDesktop {
        display: block;
    }

    .first-site {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 0.375rem;
    }

    .l-height {
        line-height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    .admin-reassign-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 1.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                width: 8.875rem;
            }

            .termed-padd {
                padding-left: 25px;
            }
            .th:first-child {
                width: 8.875rem !important;
            }
            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

//Small Screen

@media only screen and (max-width: 50rem) {
    .reassign-table-first-col {
        position: relative !important;
        left: 0px !important;
        top: 11px !important;
    }

    .checkbox-section {
        width: 50px;
        float: left;
        margin: 42px 0 0 0;
    }
    .reassign-table-first-col {
        position: relative;
        left: 0px;
        top: 10px;
    }
    .checkbox-mob-padd {
        margin-top: 40px;
    }
    .reassign-top {
        margin-bottom: 15px;
    }
    .displayBlock {
        display: block;
    }
    .date-column {
        min-width: 130px !important;
    }
    .showMobile {
        display: block;
    }
    .showDesktop {
        display: none;
    }
    .reassign-btn-section {
        width: 220px;
        display: block;
        padding: 0;
        float: inherit;
    }
    .checkbox-section.showdesktop-checkbox {
        display: none;
    }

    .checkbox-section.showmobile-checkbox {
        display: block;
    }

    .admin-reassign-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 2.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        overflow: auto;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                min-width: 60px;
            }

            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

// .sorting-arrow-down {
//     background: url(../assets/images/account-arrow-down.png) 5px 5px no-repeat;
//     display: map-get($display, "inline-block");
//     width: 20px;
//     cursor: pointer;
// }

// .sorting-arrow-up {
//     background: url(../assets/images/account-arrow-up.png) 4px 5px no-repeat;
//     display: map-get($display, "inline-block");
//     width: 20px;
//     cursor: pointer;
// }

/******* Admin Search Section *******/
.agent-search-container input.form-control {
    height: 44px;
    border: 1px solid #636466;
    border-radius: 2px;
}

.agent-search-container button.btn.btn-secondary {
    height: 44px;
    border: 1px solid #636466;
    padding: 0 10px;
    background-color: #f1f3f4;
    border-radius: 2px;
}
