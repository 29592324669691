$position-fixed: fixed;
$position-absolute: absolute;
$position-relative: relative;
$zero: 0;
$none: none;
$block: block;
$in-block: inline-block;
$font: 1rem;
$left: left;
$right: right;
$primary-aflac-blue: #0071ad;
$primary-grey: #555;
$primary-light-grey: #f1f3f4;
$primary-white: #fff;
$primary-orange: #f89728;
$primary-aflac-light-blue: #ecfaff;
$alert-danger: #d13719;
$full-percentage: 100%;
$underline: underline;
$auto: auto;
$bg-gradient: #ecfaff;
$flex: flex;
$center: center;
#TilesSection {
    background: #fff;
    // padding-top: 3.75rem;
}

h4.tiles-heading {
    @media screen and (max-width: 37.5rem) {
        font-size: 1.375rem;
        text-align: center !important;
        padding: 0 1.25rem 2.5rem !important;
    }
}
#hightlightSection {
    background: #005d8e;
    padding-top: 5rem;
    padding-bottom: 0.45rem;
    min-height: 28.75rem;
    h4 {
        color: #fff;
    }
    h4.activesection-heading {
        padding-bottom: 2.5rem !important;
        @media screen and (max-width: 37.5rem) {
            font-size: 1.75rem;
            text-align: center;
        }
    }

    .suggestive-ProductInformation {
        section {
            box-shadow: none !important;
        }
        .grid-container {
            margin-bottom: 2.5rem !important;
        }
    }
}

.companyUI .vs__dropdown-menu {
    width: 320px !important;
}
body {
    box-sizing: border-box;
    height: $full-percentage;
    #suggestiveSelling {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
            .suggestive-ProductInformation section {
                width: 22.0625rem;
            }
        }
        .suggestive-aflac {
            margin-top: $zero !important;
            height: 3.75rem;
            padding: 1.0625rem 0 0 1.125rem;
            img {
                width: 4rem !important;
                height: 1.375rem !important;
            }
        }
        .suggestive-heading {
            padding-top: $font + 1.132;

            h1 {
                padding-bottom: $font;
            }
        }
        .suggestive-content {
            padding-top: 0.875rem;
            p {
                font-size: $font + 0.125;
                color: $primary-grey;
                line-height: $font + 0.75;
                padding-bottom: 0.6875rem;
            }
            a {
                font-size: $font + 0.125;
                color: $primary-aflac-blue;
            }
        }
        .suggestive-video {
            display: $flex;
            video {
                width: $full-percentage;
                padding-left: $zero;
            }
            .right {
                background: $primary-aflac-blue;
                border-radius: $font / 4;
                padding: $font + 0.25;
                color: $primary-white;
                text-align: $center;
                width: 14.125rem;
                height: 19.3125rem;
                img {
                    padding-top: 0.9375rem;
                }
                p {
                    font-size: $font + 0.125;
                    font-weight: $light;
                    padding-top: $font + 0.75;
                    width: 90% !important;
                    margin: $auto;
                    line-height: $font + 0.5;
                }
                button {
                    border: $none;
                    background-color: $primary-white;
                    color: $primary-aflac-blue;
                    margin-top: $font + 1.1875rem;
                    font-weight: $semibold;
                    font-size: 1.125rem;
                }
            }
        }
        .suggestive-Address-new {
            ul {
                list-style-type: $none;
                display: $flex;
                padding: $zero;
                justify-content: center;
                margin: $zero;
                li {
                    text-align: $center;
                    :nth-child(1) {
                        margin: $auto;
                    }
                }
                a {
                    color: $primary-white;
                }
                h4 {
                    color: $primary-white;
                    font-size: 1rem;
                    font-weight: $normal;
                    margin: 0;
                    padding: $zero !important;
                    line-height: 1.5rem;
                }
            }
        }
        .suggestive-ProductInformation {
            div.full-width {
                width: $full-percentage;
            }
            section.suggestive-Details {
                padding: $font + 0.875 $font + 0.875 $font + 1.875 $font + 0.75;
                color: $primary-grey;
                position: $position-relative;
                border: 1px solid #707070;
                h2 {
                    margin: $zero;
                    font-size: $font + 0.375;
                    padding: $zero;
                }
                h4 {
                    padding-top: $font + 0.25;
                    font-size: $font + 0.375;
                    border-bottom: 0.0625rem $solid $primary-grey;
                    padding-bottom: 0.3125rem;
                }
            }
            .suggestive-tiles {
                display: $flex;
                flex-wrap: wrap;
            }
            h6 {
                font-size: 2.1875rem;
                font-weight: $bold;
                line-height: $font + 1.625rem;
                color: $primary-aflac-blue;
            }
            a.closeIcon {
                float: $right;
                margin: $zero;
                color: $primary-grey;
                font-weight: $semibold;
                border-bottom: $none;
                padding-top: 8px;
                position: absolute;
                right: 26px;
            }
            section {
                display: $flex;
                background: $primary-white;
                border-radius: $font / 4;
                min-height: 15.5625rem;
                padding: $zero $font $zero;
                margin-bottom: 0.875rem;
                position: relative;
                opacity: 1;
                box-shadow: 0.25rem $zero 0.9375rem #efeeee;
                @media screen and (min-device-width: 1024px) {
                    width: 24.0625rem;
                }

                // height: 15.5625rem;

                img {
                    width: $auto;
                    z-index: 9;
                    position: absolute;
                    z-index: 9999;
                    right: 0;
                    top: 2rem;
                }
                h5 {
                    color: $primary-grey;
                    font-size: $font + 0.375;
                    padding-top: $font;
                    font-weight: 600;
                }
                a {
                    display: $in-block;
                    margin-bottom: 1.3rem;
                    font-size: $font + 0.125;
                    border-bottom: $zero $solid $primary-aflac-blue;
                    line-height: $font + 0.25;
                    font-weight: $semibold;
                    color: $primary-aflac-blue;
                    text-decoration: $underline;
                }
                a:hover {
                    text-decoration: $none;
                }
                span.head {
                    font-weight: $semibold;
                }
                a.linkDetails {
                    margin: $zero;
                    padding-top: $font;
                    padding-bottom: $font;
                    font-size: $font + 0.125;
                    text-decoration: $underline;
                    font-weight: $semibold;
                }
                span {
                    font-size: $font + 0.125;
                    display: $block;
                    color: $primary-grey;
                    height: 7.5rem;
                    width: 11.875rem;
                    // @media screen and (max-width: 37.5rem) {
                    //     width: 220px;
                    // }
                    overflow: $auto;
                    line-height: $font + 0.687;
                }
            }
        }
        .suggestive-message {
            background-color: #0a8145;
            padding: 0.625rem 0.9375rem;
            font-size: $font + 0.125;
            height: 3.125rem;
            position: $position-fixed;
            width: 100%;
            z-index: 9;
            top: $zero;
            span {
                color: $primary-white;
                font-weight: $semibold;
            }
            .icon.eye-icon {
                fill: $primary-white;
                margin-right: 0.4375rem;
            }
            .eye-icon-left {
                float: left;
                top: 5px;
                position: relative;
            }
        }
        .suggestive-Footer {
            background-color: #f4f4f4;
            padding-top: 2.275rem;
            padding-bottom: 1.32rem;
            margin-top: 0px !important;
        }
        .suggestive-Footer p {
            padding-bottom: $zero;
            font-size: 0.75rem;
        }
        .modal-dialog {
            .modal-content {
                .modal-header {
                    h4 {
                        font-size: $font + 0.125;
                        font-weight: $bold;
                        color: $primary-grey;
                    }
                }
                .modal-body {
                    font-size: $font;
                }
            }
            .employee-footer.modal-footer {
                border-top: 0px !important;
            }
        }
        @media only screen and (max-width: 37.5rem) {
            .contact-left a {
                padding: 10px 5px;
            }
            .pre-add-content a {
                font-size: 1rem;
                padding: 10px 5px;
            }
            h4.tiles-heading {
                padding-bottom: 2.5rem !important;
            }
            section.suggestive-Details h4 {
                padding-left: 0px !important;
            }
            .state-menu .vs__dropdown-toggle {
                width: 100%;
            }
            .suggestive-video {
                display: $block;
            }
            .suggestive-container {
                padding: $zero $font + 0.25rem;
            }
            .suggestive-container {
                width: $full-percentage;
            }

            .suggestive-message-main {
                width: $full-percentage;
                padding-left: 0.5rem;
            }
            .suggestive-video-content {
                width: $full-percentage !important;
            }
            p.video-content {
                width: 55% !important;
                padding-bottom: $font + 0.25rem;
            }
            .suggestive-Address-new ul {
                display: $block;
            }
            .suggestive-ProductInformation section {
                width: 20.9375rem !important;
            }

            .suggestive-ProductInformation section img.life-duck {
                // right: $font + 0.5625 !important;
                top: $font + 0.875;
            }
            // .suggestive-ProductInformation section img.cancer-duck {
            //     right: 3.25rem !important;
            // }
            .suggestive-ProductInformation section img.vision-duck {
                width: 9.5rem;
                height: 11.4375rem;
                // right: $font + 0.6875 !important;
                top: $font + 0.25;
            }
            .suggestive-ProductInformation section img.dental-duck {
                width: 10.875rem;
                height: 10.1875rem;
                top: 2.6875rem;
                right: -0.625rem;
                // right: 2.4375rem !important;
            }
            .suggestive-ProductInformation section img.hospital-duck {
                width: 9.375rem;
                height: 12.25rem;
                top: $font;
                // right: $font + 0.8125 !important;
            }
            .suggestive-ProductInformation section img.critical-duck {
                width: 9.1875rem;
                height: 12.4375rem;
                top: 0.75rem;
                // right: $font + 1.8125rem !important;
            }
            .suggestive-ProductInformation section img.Ascendital-duck {
                width: 10.6875rem;
                height: 11.4375rem;
                top: $font + 1.1875;
                // right: $font + 1.875rem !important;
            }
            .suggestive-ProductInformation section img.disability-duck {
                width: 11.375rem;
                height: 11.625rem;
                top: 1.75rem;
            }

            .suggestive-data-container {
                width: $full-percentage !important;
            }

            .suggestive-info {
                padding-bottom: 6rem !important;
            }
        }
        button.btn.btn-secondary.outline.suggestive-btn-modal {
            width: 18.9375rem !important;
            margin-bottom: 7px;
        }
        @media only screen and (max-width: 47.9375rem) {
            .suggestive-less {
                padding: $zero;
            }
            // .accident-duck {
            //     right: 2rem !important;
            // }
        }
        .accident-duck {
            right: 0rem !important;
        }
        .disability_border {
            border-bottom: 0.5rem $solid #94b7bb;
        }
        .accident_border {
            border-bottom: 0.5rem $solid #aa8066;
        }
        .hospital_border {
            border-bottom: 0.5rem $solid #ff8200;
        }
        .dental_border {
            border-bottom: 0.5rem $solid #69b3e7;
        }
        .critical_illness {
            border-bottom: 0.5rem $solid #e1523d;
        }
        .Vision_border {
            border-bottom: 0.5rem $solid #485cc7;
        }
        .life_border {
            border-bottom: 0.5rem $solid #f6eb61;
        }
        // .cancer_border {
        //     border-bottom: 0.5rem solid #d43997;
        // }
        .cancer_border {
            border-bottom: 0.5rem solid #1798c2;
        }
        .red {
            border-bottom: 0.5rem $solid red;
        }
    }
}

.suggestive-data-container {
    width: 100% !important;
}

/****** Tiles Section CSS Starts ********/
.suggestive-ProductInformation section img {
    position: $position-relative;
    z-index: -1;
    top: 2rem !important;
}
.suggestive-ProductInformation section img.life-duck {
    right: 0rem !important;
    top: 3rem;
    height: 169px;
}
.suggestive-ProductInformation section img.cancer-duck {
    @media screen and (max-width: 768px) {
        right: $zero;
    }
    right: 1rem !important;
}

.suggestive-ProductInformation section img.vision-duck {
    width: 10.125rem;
    height: 11.4375rem;
    // right: 25px !important;
    top: $font + 0.25;
}
.suggestive-ProductInformation section img.dental-duck {
    width: 10.875rem;
    height: 10.1875rem;
    top: 2.6875rem;
}
.suggestive-ProductInformation section img.hospital-duck {
    width: 9.375rem;
    height: 12.25rem;
    top: $font;
    right: $zero !important;
}
.suggestive-ProductInformation section img.critical-duck {
    width: 9.1875rem;
    height: 12.4375rem;
    top: 0.5rem;
    right: $zero !important;
}
.suggestive-ProductInformation section img.Ascendital-duck {
    width: 10.6875rem;
    height: 11.4375rem;
    top: 0.75rem;
    right: $zero !important;
}
.suggestive-ProductInformation section img.disability-duck {
    width: 11.375rem;
    height: 11.625rem;
    top: $font + 0.25;
    @media screen and (max-width: 768px) {
        right: -0.625rem !important;
    }
}

.collapsible {
    background-color: #777;
    color: $primary-white;
    cursor: $pointer;
    padding: $font + 0.125;
    width: $full-percentage;
    border: $none;
    text-align: $left;
    outline: $none;
    font-size: 0.9375rem;
}

// .active,
// .collapsible:hover {
//     background-color: $primary-grey;
// }

.content {
    padding: 0 $font + 0.125;
    display: $none;
    overflow: $hidden;
    background-color: #f1f1f1;
}

.suggestive-Address-new ul li:first-child {
    padding-right: $font + 0.45;
    position: relative;
    top: -15px;
    width: 160px;
}

.suggestive-container {
    width: 49.75rem;
    margin: $zero $auto;
}

.suggestive-message-main {
    width: 49.75rem;
    margin: $zero $auto;
}

.suggestive-message-main span {
    display: $block;
}

.suggestive-message-main .icon.cross {
    fill: $primary-white;
}

.suggestive-Address-new h4 {
    font-size: $font + 0.125rem;
    color: $primary-white;
    font-weight: $normal;
}

.suggestive-Address-new h5 {
    font-size: $font + 0.375rem;
    color: $primary-white;
    font-weight: $normal;
    line-height: 0.8rem;
}

.pre-add-content {
    display: inline-flex;
    font-size: $font + 0.125;
    color: $primary-aflac-blue;
    padding-right: 33px !important;
    padding: 0rem 0 0 0;
}

.content-wrapper .floating-label .suggestive-date-field {
    float: left;
    max-width: 24rem !important;
    width: 100% !important;
}

// .up-arrow {
//     width: $zero;
//     height: $zero;
//     border-left: 0.9375rem $solid transparent;
//     border-right: 0.9375rem $solid transparent;
//     border-bottom: $font + 0.25 $solid $primary-white;
//     position: $position-absolute;
//     top: -1.25rem;
//     left: $font + 0.875;
// }

.suggestive-up-arrow {
    background: url(../assets/images/up-triangle.png) 0 0 no-repeat;
    width: 42px;
    height: 44px;
    top: -17px;
    position: absolute;

    left: 35px;
}

.suggestive-up-arrow-new {
    background: url(../assets/images/up-triangle.png) 0 0 no-repeat;
    width: -3px;
    /* height: 0; */
    border-left: 0.9375rem solid transparent;
    @media screen and (max-width: 37.5rem) {
        right: 25px !important;
        left: 21px !important;
    }
    border-right: 0.9375rem solid transparent;
    border-bottom: 1.25rem solid #fff;
    position: absolute;
    top: -17px;
    height: 44px;
    width: 42px;
    right: 35px;
}

.icon.calender-icon {
    fill: $primary-white;
    width: $font + 1.25;
    height: $font + 2.75;
    position: $position-relative;
    top: 0.875rem;
}

.suggestive-btn {
    background-color: $primary-white !important;
    width: 10rem;
    height: 3.125rem;
}

.suggestive-Address-new {
    width: $auto;
    display: table;
    margin: $zero $auto;
    padding: 1.6rem 0 1rem 0;
    background: #0071ad;
    width: 632px;
    max-height: 295px;
    border-radius: 4px;
    margin-top: 2.85rem;
}

img.suggestive-profile-pic {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
}

.suggestive-data-container p {
    font-size: $font + 0.125;
    padding: 0.5rem 0 0 0;
}

.head {
    font-size: $font + 0.125;
    font-weight: $semibold;
}

.modal-footer {
    margin: $zero $auto;
}

.padding-extra {
    padding-right: 0.625rem;
}

.contact-left {
    width: $auto;
    float: $left;
}

.contact-right {
    width: $auto;
    float: $right;
}

.suggestive-message-main p {
    display: inline;
    padding: 0.0625rem 0 0 0.625rem;
    position: $position-absolute;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    p.suggestive-close {
        display: $none;
    }

    .protect-future-section p {
        width: 100% !important;
    }
    .state-menu .vs__dropdown-toggle {
        width: 100% !important;
    }
}
.suggestive-message-main img.eye-icon {
    cursor: pointer;
}
.grid-container1 {
    display: flex !important;
}
.grid-container {
    display: grid;
    grid-gap: 8px !important;
    grid-auto-flow: dense;
}
.grid-item-suggestive {
    display: $flex;
    margin: 0px 7px 0px 7px !important;
}

/****** Suggestive CSS Starts *******/
.protect-future-section {
    background: $primary-aflac-blue;
    height: 30.8875rem;
    text-align: center;
}

.protect-future-section h2 {
    font-size: 2.75rem;
    color: $primary-white;
    line-height: 3.25rem;
    padding-top: 6.1875rem;
    padding-bottom: 1.25rem;
}

.protect-future-section h3 {
    font-size: 2.1875rem;
    color: $primary-white;
    line-height: 2.625rem;
    padding-top: 2.5rem;
    padding-bottom: 1.13rem;
    font-weight: $bold;
}

.protect-future-section p {
    font-size: 1.125rem;
    color: $primary-white;
    line-height: 1.6875rem;
    margin: $zero;
    padding: 0 7.5625rem;
    width: 65% !important;
    margin: $auto;
}

/****** Family Group Radio Button CSS Starts *******/
.family-group-section .radio-toolbar {
    margin: 0.625rem;
    display: inline-block;
    // width: 100%;
}

.family-group-section .radio-toolbar input[type="radio"] {
    opacity: $zero;
    position: fixed;
    width: $zero;
}

.family-group-section .radio-toolbar label {
    display: inline-block;
    background-color: transparent;
    padding: 0.625rem 0.75rem !important;
    font-size: 1.125rem;
    border: 2px solid #fff;
    border-radius: 0.25rem;
    color: $primary-white;
    margin: $zero 0.625rem !important;
    line-height: 1.25rem;
    width: 10rem;
}

span.pre-add-content.contact-email {
    position: relative;
    left: 20px;
}

label.extra-width {
    width: 10.3125rem !important;
}
//   .family-group-section .radio-toolbar label:hover {
//     background-color: #dfd;
//   }

.family-group-section .radio-toolbar input[type="radio"]:checked + label {
    border: 2px solid #fff !important;
    background-color: #fff !important;
    color: $primary-aflac-blue !important;
}

#suggestiveSelling {
    .family-group-section .radio-toolbar label {
        font-weight: 600 !important;
    }
}
.suggestive-control-input {
    cursor: pointer;
    height: 44px;
    width: 44px;
    left: 2px !important;
    top: -9px !important;
}

.family-group-section [type="radio"]:checked + label:before {
    display: $none;
}

.family-group-section [type="radio"]:checked + label:after {
    display: $none;
}

.family-group-section [type="radio"]:not(:checked) + label:before {
    display: $none;
}
.gender-section [type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute !important;
    left: 13px !important;
    z-index: 99999 !important;
}
/****** Family Group Radio Button CSS Ends *******/

.tiles-heading {
    margin-top: 3.8125rem !important;
    font-size: 1.375rem !important;
    line-height: 2.0625rem !important;
}

#TilesSection h4.tiles-heading {
    padding-bottom: 2.5rem !important;
}

.family-form-section h4 {
    font-size: 1.45rem;
    color: $primary-aflac-blue;
    line-height: 2.25rem;
    margin: $zero;
    font-weight: $bold;
    padding: 0 0 1.25rem !important;
    text-align: center;
    //padding: 0 7.5625rem;
}

h4.content-head {
    text-align: left !important;
    color: #555 !important;
    font-weight: 600 !important;
    padding: 0 !important;
    word-break: break-word;
}

.info-text-section {
    max-width: 49.75rem;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        width: 75% !important;
    }
    p {
        margin-bottom: 0.9375rem !important;
    }
    margin: $zero $auto !important;
    color: $primary-grey;
    line-height: 1.6875rem;
    text-align: center;
}

.tiles-info-text-section {
    width: 43%;
    @media screen and (max-width: 48rem) {
        width: 75%;
        text-align: center;
    }

    margin: $zero $auto !important;
    color: $primary-grey;
    line-height: 1.25rem;
    display: flex;
    padding-bottom: 1.5125rem;
}

.gender-section {
    font-size: 0.875rem;
    color: $primary-grey;
    padding: 1.2rem $zero 1.3rem 2.6875rem;
}

.gender-section p {
    font-size: 0.875rem;
    color: $primary-grey;
    padding: $zero 1.5rem $zero $zero;
}

.gender-section [type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
    //padding-left: 52px!important;
    padding-right: 0.67rem !important;
}

.padd-left28 {
    padding-left: 1.75rem;
}

.family-form-section.content-wrapper {
    margin: 3.35rem 0 0 0 !important;
}

.form-parrent-div {
    position: $position-relative;
    width: $full-percentage;
    // height: 16.0875rem;
    margin-bottom: 5rem;
}

.group-form-section {
    width: $full-percentage / 2;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        width: 68% !important;
    }
    margin: $zero $auto;
}

// .content-wrapper .floating-label .date-field {
//     float: left;
//     max-width: 19rem !important;
//     width: 100% !important;
// }

.btn-view-product {
    width: 10.75rem;
    height: 3.125rem;
    padding-top: 1.275rem;
}

.mr-lft15 {
    margin-left: 1.8125rem;
}

.suggestive-ProductInformation {
    display: $flex;
    justify-content: center;
}

p.return-text {
    text-align: center;
    padding: 1.5rem $zero 2rem $zero;
    line-height: 1.6875rem;
}

p.return-text a {
    text-decoration: $underline;
}

.top-section {
    text-align: center;
    color: #fff;
}

.top-section p {
    font-size: 1.125rem;
    font-weight: $normal;
    padding: 0.8rem $zero 0.4rem $zero;
}

.contact-btn {
    background: white;
    color: #0071ad;
    font-size: 1.125rem;
    font-weight: 600;
    width: 21.875rem;
}

.contact-btn:hover,
.contact-btn:focus {
    background: white !important;
    color: #0071ad !important;
}

/***** CSS for Desktop form dropdown ******/
.floating-label.custom-list.m-form-wdth input.vs__search {
    margin: $zero !important;
    width: 18.4375rem !important;
}

// .floating-label.custom-list .vs--single.vs--searchable {
//     width: 158px;
// }

.v-select.floating-input.vs--single.vs--searchable input.vs__search {
    margin: $zero !important;
}

@media screen and (min-width: 37.5rem) {
    .gender-section p {
        padding: 0.2rem 1.5rem $zero $zero;
    }
    // .gender-section [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
    //     padding-right: 0.67rem !important;
    //     padding-left: 53px!important;
    // }
    .contact-left a {
        padding: 10px 5px;
    }
    .pre-add-content a {
        font-size: 1.125rem;
        padding: 10px 5px;
    }
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
    .grid-item-suggestive:nth-child(2n + 2) {
        grid-column: 1 / 3;
    }
}
.hideContainer {
    display: none;
    position: relative;
}

.suggestive-links {
    display: flex;
    flex-flow: column;
    // margin-top: 14px;

    & a {
        :last-child {
            padding-top: 1rem;
            padding-bottom: 0.25rem;
        }

        :visited {
            text-decoration: none;
        }
    }
}
.bc-player-default_default {
    height: 100%;
    width: 100% !important;
}
.videoSection_popu {
    height: 100%;
    height: 85vh !important;
}

.employee-modal-main.modal-content {
    width: 21.4375rem !important;
    margin: 0 auto !important;
}
.label-color {
    color: #0071ad !important;
    padding-top: 12px !important;
}

.suggestive-video {
    .customvideoHeight {
        height: 300px !important;
    }
}

.pre-add-content a {
    font-size: 1rem;
}

@media only screen and (max-width: 37.5rem) {
    .suggestive-email_ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 280px !important;
    }
    .gender-section p {
        padding: 0.2rem 1.5rem $zero $zero;
    }
    // .gender-section [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
    //     padding-right: 0.67rem !important;
    //     padding-left: 53px!important;
    // }
    h4.tiles-heading {
        padding-bottom: 2.5rem !important;
    }
    section.suggestive-Details h4 {
        padding-left: 0px !important;
        font-weight: bold !important;
    }
    .contact-left {
        float: inherit !important;
        // padding-bottom: 0.4rem;
    }

    .contact-right {
        float: inherit !important;
        padding-bottom: 5px;
    }

    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 2.5rem !important;
        right: 2.1rem !important;
    }

    .footer-links a {
        color: #555;
        font-size: 14px;
        font-weight: 600;
        display: block;
        text-align: left;
        width: 100%;
        padding: 6px 0 13px 0 !important;
        margin: 0 0 4px 0;
    }
    .privacy-links {
        padding: 0 0 10px 0;
    }

    /****** Suggestive Selling ********/

    .suggestive-aflac {
        display: $none;
    }

    .protect-future-section p {
        width: $full-percentage;
        display: contents;
    }

    .suggestive-Address-new {
        width: 20.9375rem;
        height: 29.9075rem;
    }

    .protect-future-section {
        height: auto;
        padding: 0 1.25rem 2.475rem 1.25rem;
    }

    .family-group-section .radio-toolbar {
        margin: $zero;
    }

    .family-group-section .radio-toolbar label {
        margin: $zero $auto !important;
        width: 20.9375rem !important;
        margin-bottom: 1.25rem !important;
    }

    label.extra-width {
        width: 100% !important;
    }

    section.info-text-section {
        width: 100%;
        padding: $zero 1.25rem;
    }

    .form-parrent-div {
        height: auto;
        margin: 0 0 5.025rem 0;
    }

    .group-form-section {
        display: contents;
        width: 100%;
    }

    p.float-left {
        width: 100%;
        padding-bottom: 9px;
    }

    .m-gender {
        width: 100%;
        padding-bottom: 6px;
    }

    .input-group.date.date-field.m-form-wdth {
        max-width: 20.875rem !important;
        width: 20.875rem !important;
    }

    .m-form-wdth {
        max-width: 20.875rem !important;
        width: 20.875rem !important;
    }

    .btn-view-product {
        width: 20.9375rem;
        margin: $zero $auto;
    }

    .family-form-section h4 {
        text-align: left;
        padding: $zero 1.25rem !important;
    }

    section.tiles-info-text-section {
        width: $full-percentage;
        padding: 1.25rem 1.25rem 2.5rem 1.25rem;
        line-height: 1.25rem;
        font-weight: $normal;
    }

    .tiles-info-text-section p {
        margin: $zero;
        font-size: 1.125rem;
        line-height: 1.25rem;
    }

    p.return-text {
        padding: 1.2375rem 1rem 1.25rem 1rem;
    }

    .top-section p {
        padding: 0.9rem 0 0.2rem 0;
    }

    .top-section {
        padding: 1.0625rem 0.75rem $zero 0.75rem;
    }

    body .suggestive-Address-new ul li {
        padding: $zero !important;
    }

    h4.mobile-head {
        text-align: $center;
        padding: $zero $zero 0.625rem $zero !important;
        line-height: 1.8rem;
        font-size: 1.125rem !important;
        font-weight: $normal !important;
    }

    h5.mob-contact {
        padding: 17px 0 7px 0 !important;
        line-height: 28px;
        display: inline-block;
    }

    .contact-btn {
        width: 18.4375rem;
        margin: 0.8rem $zero $zero $zero;
    }

    .contact-btn:hover,
    .contact-btn:focus {
        background: white !important;
        color: #0071ad !important;
        width: 18.4375rem;
    }

    .protect-future-section h2 {
        padding-top: 3.75rem;
        padding-bottom: 1.25rem;
    }

    .protect-future-section h3 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .info-text-section p {
        padding: 1.25rem $zero 0.8rem $zero;
        display: inline-block;
        font-size: 1.125rem;
        line-height: 1.6875rem;
        margin-bottom: $zero !important;
    }

    .m-form-padd {
        padding: $zero 5px;
    }

    h4.family-form-head {
        text-align: $center;
    }

    .gender-section {
        padding: 0rem $zero 1rem 2.225rem;
    }

    .padd-left28 {
        padding: $zero 1.25rem;
    }

    // #TilesSection {
    //     padding-top: 3.75rem;
    // }

    .suggestive-Address-new ul li:first-child {
        top: -7px;
        width: $auto !important;
    }

    .pre-add-content {
        padding: $zero !important;
    }

    .pre-add-content.contact-email {
        position: relative;
        left: 0px !important;
    }
}
@media screen and (orientation: portrait) {
    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 0.8rem !important;
        right: 0rem !important;
    }
}

@media screen and (orientation: landscape) {
    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 0rem !important;
        right: 1.5rem;
        // right: 6.5rem !important;
    }
}

.employee-content-section {
    display: ruby-base !important;
}

.footer-links {
    overflow: hidden;
    padding: 0px;
}

.footer-links a {
    color: $primary-grey;
    font-size: 0.875rem;
    font-weight: $bold;
    padding: 0 0 0 15px;
}

.footer-links a:hover {
    color: #4c4c4c;
}

p.AflacFooterContent {
    text-transform: uppercase;
}
.invalid-msg {
    color: #d13719;
    font-size: 0.75rem;
    line-height: 0.93rem;
}

// .duck-png-4 {
//     background: url(assets/images/suggestive-duck1a.png) 0 0 no-repeat;
//     width: calc(100% - 36rem);
// }

// .duck-container {
//     display: block !important;
//     height: 36rem;
// }

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
    body .suggestive-ProductInformation section {
        width: 22.0625rem !important;
    }

    body .suggestive-ProductInformation section img.disability-duck {
        right: 0rem !important;
        top: 2.5rem !important;
    }

    body {
        #suggestiveSelling {
            .accident-duck {
                right: 0rem !important;
                top: 2.8rem !important;
            }
        }
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    // img.accident-duck {
    //     right: 1.1rem !important;
    // }
    img.life-duck {
        right: 0 !important;
    }
    // img.dental-duck {
    //     right: 1.2rem !important;
    // }
    // img.disability-duck {
    //     right: 1.1rem !important;
    // }
    .suggestive-container {
        width: auto;
        margin: $zero $auto;
    }

    .suggestive-message-main {
        width: 45.75rem;
    }

    body .suggestive-container {
        padding: 0 20px;
    }

    body .suggestive-ProductInformation section {
        width: 22.0625rem;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
    .suggestive-container {
        width: 49.5rem;
        margin: $auto !important;
    }
}

body .suggestive-ProductInformation section .full-width .closeIcon img {
    /* top: 0 !important;*/
    top: -9px !important;
    right: -7px !important;
}

body .suggestive-logo.suggestive-aflac img {
    width: 4rem !important;
    height: 1.375rem !important;
}
.suggestive-info {
    padding-bottom: 6rem !important;
}
.state-menu .vs__dropdown-toggle {
    width: 138px;
}

// Details section border-----

.disability_border_Details {
    border-bottom: 0.5rem $solid #94b7bb !important;
}

.accident_border_Details {
    border-bottom: 0.5rem $solid #aa8066 !important;
}
.hospital_border_Details {
    border-bottom: 0.5rem $solid #ff8200 !important;
}
.dental_border_Details {
    border-bottom: 0.5rem $solid #69b3e7 !important;
}
.illness_border_Details {
    border-bottom: 0.5rem $solid #e1523d !important;
}
.vision_border_Details {
    border-bottom: 0.5rem $solid #485cc7 !important;
}
.life_border_Details {
    border-bottom: 0.5rem $solid #f6eb61 !important;
}
// .cancer_border_Details {
//     border-bottom: 0.5rem solid #d43997 !important;
// }
.cancer_border_Details {
    border-bottom: 0.5rem solid #0082af !important;
}
.red_Details {
    border-bottom: 0.5rem $solid red !important;
}
@media screen and (max-width: 37.5rem) {
    .gender-section {
        position: relative;
    }
    .m-gender {
        position: relative;
    }
    .sex-required-msg {
        position: absolute;
        bottom: 12px;
    }
}
.loader-animation-suggestive {
    width: 80px;
    position: relative;
    // top: 12px;
    // @media only screen and (min-width: 50rem) {
    //     top: 12px;
    // }
}

div.preview-less img {
    width: max-content !important;
    height: max-content !important;
    top: 2rem !important;
}

.return-previous-page {
    @media screen and (max-width: 37.5rem) {
        padding-left: 20px;
    }
    max-width: 640px;
    margin: 0 auto;
    padding-top: 20px;
    font-size: 1.125rem;
    text-decoration: underline;
}
.suggestive-aflac {
    img.client-logo {
        display: none;
    }
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 235px;
    text-align: left !important;
}
// .pre-add-content{
//     display: block!important;
// }
span.vs__selected {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 290px;
}
html {
    scroll-behavior: smooth;
}
.suggestive-email_ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
}
.suggestive-ProductInformation .employee-content-section {
    padding-top: 10px;
}
#suggestiveSelling {
    .btn-view-product {
        button {
            font-weight: 600 !important;
        }
    }
}

.suggestive-ProductInformation {
    section {
        img.illness_duck {
            right: 1rem !important;
        }
    }
}
#suggestiveSelling .suggestive-ProductInformation section img.illness-duck {
    right: 1rem !important;
}

.contentSpanishSST .suggestive-Address-new h5 {
    font-size: 1.375rem;
    color: #fff;
    font-weight: 400;
    line-height: 2rem;
    margin-top: -14px !important;
    /* margin-bottom: -38px !important; */
    padding-bottom: 0px !important;
    text-align: left;
}
.contentSpanishSST img.suggestive-profile-pic {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    margin-top: 25px !important;
}
