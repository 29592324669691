$position-fixed: fixed;
$position-absolute: absolute;
$position-relative: relative;
$zero: 0;
$none: none;
$block: block;
$in-block: inline-block;
$font: 1rem;
$left: left;
$right: right;
$primary-aflac-blue: #0071ad;
$primary-grey: #555;
$primary-light-grey: #f1f3f4;
$primary-white: #fff;
$primary-orange: #f89728;
$primary-aflac-light-blue: #ecfaff;
$alert-danger: #d13719;
$full-percentage: 100%;
$underline: underline;
$auto: auto;
$bg-gradient: #ecfaff;
$flex: flex;
$center: center;

body .faq-header {
    margin-top: 0 !important;
    height: 3.75rem;
    padding: 1.0625rem 0 0 1.125rem;
    border-bottom: 1px solid #eaeaea;
}

body .faq-header img {
    width: 4rem !important;
    height: 1.375rem !important;
}

.faq-main-section {
    text-align: $left;
    padding: 0 0 0 3.75rem;
}

.faq-main-section h2 {
    font-size: 2.75rem;
    color: $primary-aflac-blue;
    line-height: 3.25rem;
    padding: 4.8125rem 0 2.5rem 0;
    margin: $zero;
}

.faq-main-section p {
    font-size: 1.125rem;
    color: #555;
    line-height: 1.6875rem;
    margin: 0;
    padding: 0;
}
.question-answer {
    margin: 0 0 20px 0;
}

p.qus-bold {
    font-weight: bold;
}

.faq-qus-ans-container {
    display: inline-block;
    width: 100%;
    // height: 500px;
    overflow-y: auto;
}

@media only screen and (max-width: 37.5rem) {
    .faq-main-section {
        text-align: left;
        padding: $zero 1.25rem;
    }
}
