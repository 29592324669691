.microsite-section {
    @include space(margin, top, $half);
    float: $left;
    width: $percentage * 10;
    max-width: 40rem;
}

.radio-section .radio p {
    display: map-get($display, "inline-block");
    padding: $zero $zero $zero 0.938rem;
}

label.radio-lbl {
    display: map-get($display, "contents");
    color: map-get($theme-colors, "grey");
    font-size: $font + 0.125;
}

.invalid-danger {
    color: #d13719 !important;
}

/**
 This is css used as global to remove v-sselect white background
*/
.vs__dropdown-toggle {
    background: none !important;
}
