$position: (
    "fixed": fixed,
    "absolute": absolute,
    "relative": relative
);

$display: (
    "block": block,
    "contents": contents,
    "flex": flex,
    "grid": grid,
    "inline": inline,
    "inline-block": inline-block,
    "inline-flex": inline-flex,
    "inline-grid": inline-grid,
    "initial": initial,
    "none": none
);

$theme-colors: (
    "primary": #0071ad,
    "torqoise": #01a7e1,
    "light-blue": #ecfaff,
    "white": #fff,
    "light-grey": #f1f3f4,
    "medium-grey": #ccc,
    "dark-grey": #ced4da,
    "grey": #555,
    "light-black": #999,
    "black": #000,
    "orange": #f89728,
    "danger": #ff4136,
    "success": #0a8145,
    "popup-grey": #000000b3
);
