.accordion {
    border: $none;
    @include border(0.063rem $solid, map-get($theme-colors, "light-black"), bottom);
    background-color: transparent;
    color: map-get($theme-colors, "grey");
    cursor: pointer;
    font-size: ($font + 0.125) rem;
    font-weight: $bold;
    opacity: $one;
    outline: $none;
    padding: 1.125rem $zero;
    text-align: $left;
    transition: 0.4s;
    width: $percentage * 10;
}

.panel {
    @include space(padding, all, $zero);
    background-color: transparent;
    box-shadow: $none;
    display: map-get($display, "none");
    overflow: $hidden;
}

.acccount-content p {
    font-size: $font + 0.125;
    line-height: $font + 0.688;
}

.link-undeline {
    text-decoration: $underline;
}

.icon.dropdown-arrow {
    fill: map-get($theme-colors, "grey");
    height: $width;
    @include space(margin, left, $half);
    width: $width;
}

.accordion {
    background-color: transparent;
    @include border($zero $solid, map-get($theme-colors, "medium-grey"), "");
    @include border(0.063rem $solid, map-get($theme-colors, "medium-grey"), "bottom");
    color: map-get($theme-colors, "grey");
    cursor: $pointer;
    font-size: $font + 0.125;
    font-weight: $bold;
    opacity: $one;
    outline: $none;
    padding: 1.125rem $zero;
    text-align: $left;
    transition: 0.4s;
    width: $percentage * 10;
}

.panel {
    @include space(padding, all, $zero);
    background-color: transparent;
    box-shadow: $none;
    display: map-get($display, "none");
    overflow: $hidden;
}

.account-accordion {
    padding: 1rem 1.25rem $zero 1.25rem;
    width: $percentage * 10;
    span img {
        float: $right;
    }
    .parentDiv {
        position: map-get($position, "relative");
        .account-data-table-mob {
            ul {
                padding: 0 0 0 15px;
                margin: $zero;
                li {
                    list-style-type: $none;
                    display: inline-block;
                    font-size: 12px;
                    padding: 0 10px 0.6rem 0;
                    margin: 0 0 0 20px;
                    img {
                        width: 24px;
                        height: 24px;
                    }
                    span {
                        padding: 5px 10px;
                        border-radius: 0px;
                        display: inline-block;
                        top: -35px;
                        left: 60px;
                        position: absolute;
                    }
                }
            }
        }
        .account-data-content-mob {
            @include space(padding, top, $half);
            @include space(padding, bottom, $small);
            border-bottom: 0.063rem $solid map-get($theme-colors, "medium-grey");
            ul {
                @include space(margin, all, $zero);
                padding: 0 0 0 1rem;
                .li {
                    display: map-get($display, "inline-block");
                    list-style-type: $none;
                    @include space(margin, all, $zero);
                    @include space(padding, all, $small);
                    img {
                        height: 1.5rem;
                        width: 1.5rem;
                    }
                    span {
                        @include border-radius($zero);
                        background: map-get($theme-colors, "light-grey");
                        box-shadow: $zero 0.188rem 0.375rem map-get($theme-colors, "mediium-grey");
                        color: map-get($theme-colors, "dark-grey");
                        display: map-get($display, "inline-block");
                        font-size: 0.85rem;
                        left: inherit;
                        padding: 0.313rem 0.625rem;
                        position: map-get($position, "absolute");
                        right: 1.875rem;
                        top: -2.188rem;
                    }
                }
            }
            .date-label {
                color: map-get($theme-colors, "grey");
                font-size: 0.75rem;
                float: $left;
                padding: 0.6rem 1rem 0;
                width: $percentage * 10/2;
                p {
                    @include space(margin, all, $zero);
                    font-size: 0.75rem;
                }
            }
            .date-25 {
                width: 3rem;
                float: $left;
                position: map-get($position, "relative");
                text-align: right;
                @include space(margin, right, $small);
            }
            .date-content {
                float: $left;
                padding: 0.625rem 0.375rem $zero $zero;
                p {
                    @include space(margin, all, $zero);
                    @include space(padding, all, $zero);
                    color: map-get($theme-colors, "grey");
                    font-size: $font + 0.125;
                    font-weight: $bold;
                }
                span.small {
                    color: map-get($theme-colors, "grey");
                    font-size: 0.75rem;
                    padding: $zero $zero $zero 0.5rem;
                }
            }
        }
    }
}

.minus {
    float: $left;
    padding: 0.563rem 0.375rem $zero $zero;
}

button:focus {
    outline: $none;
}

button.accordion.active {
    @include border($zero $solid, map-get($theme-colors, "light-grey"), bottom);
}

@media only screen and (min-width: 50rem) {
    .account-accordion {
        display: map-get($display, "none");
    }
}
