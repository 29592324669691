.access-container {
    h2 {
        @include font(map-get($theme-colors, "white"), $size-two + 0.188, $bold, $size-two + 0.625);
        padding: $zero $zero $width * 4;
        float: $left;
        width: 100%;
        margin: 0 $auto;
    }
}

p.access-conent {
    @include font(map-get($theme-colors, "white"), $size-one + 0.125, $normal, $font + 0.75);
    margin: $zero 1.25rem;
}

.access-conent a {
    color: #fff;
    text-decoration: underline;
}

@media only screen and (max-width: 50rem) {
    .text-mobile-left {
        text-align: left !important;
    }
}
