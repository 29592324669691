.header {
    @include border(0.063rem solid, map-get($theme-colors, "light-grey"), bottom);
    background: map-get($theme-colors, "white");
    height: $logo-height;
    left: $zero;
    padding: $size-one + 0.125;
    top: $zero;
    width: $percentage * 10;
    z-index: $one * 9;
    position: map-get($position, "fixed");
    .header-logo img {
        float: $left;
        vertical-align: $text-bottom;
        width: $logo-img;
    }
    .navigation-logo {
        display: map-get($display, "block");
        float: $right;
        .menu-cross {
            display: map-get($display, "none");
        }
    }
}

#overlay-backdrop {
    display: none;
    background: #000;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.7;
    z-index: 1;
    top: 0;
}
.header-text {
    font-size: 0.95rem;
    padding-left: 10%;
    color: #555;
    @media only screen and (max-width: 50rem) {
        padding-left: 5px !important;
    }
}
