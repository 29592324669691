.main-container {
    @include space(padding, bottom, $xlarge);
}

.content-wrapper {
    //margin: $size-two $width * 2 $width-half $width * 2;
    margin: 8.0625rem $width * 2 $width-half $width * 2;
    max-width: $percentage * 10;
}

.from-container,
.from-container-lg {
    width: calc(100% - 0.25rem);
}

.access-container {
    width: calc(100% - 2.5rem);
    max-width: 27rem;
    margin: 0rem $auto $zero;
}

.enrollment-container {
    width: calc(100% - 2.5rem);
    max-width: 27rem;
    margin: 0rem $auto $zero;
}

@media only screen and (min-width: 48rem) {
    .content-wrapper {
        margin: 8.0625rem $zero $width-half $size-five + 0.625;
    }
    .main-container {
        margin-left: $size-five + 0.625;
    }
    .from-container {
        flex: $zero $zero $size-six * 6;
        max-width: $size-six * 6;
    }
    .access-container {
        // margin-top: 7.5rem;
        padding-top: 7.5rem;
    }

    .enrollment-container {
        // margin-top: 3.7rem;
        padding-top: 3.7rem;
    }
}

@media only screen and (min-width: 62rem) {
    .from-container-lg {
        flex: $zero $zero $size-five * 10;
        max-width: $size-five * 10;
    }
}

@media only screen and (min-width: 64rem) {
    .duck-container {
        display: map-get($display, "block") !important;
        height: $size-six * 6;
    }
    .duck-png-1 {
        background: url(../assets/images/duck.png) $zero $zero $no-repeat;
        width: calc(100% - 36rem);
    }
    .duck-png-4 {
        background: url(../assets/images/duck-4s.png) $zero $zero $no-repeat;
        width: calc(100% - 36rem);
    }
    .img-fluid-duck {
        max-width: $percentage * 10;
        width: $auto;
    }
    .duck-png-5 {
        background: url(../assets/images/suggestive-duck1a.png) $zero $zero $no-repeat;
        width: 250px;
        background-size: inherit;
        float: right;
        position: absolute;
        right: 0;
        top: 100px;
    }
}

@media only screen and (min-width: 75rem) {
    .duck-png-3 {
        background: url(../assets/images/duck-3s.png) $zero $zero $no-repeat;
        width: calc(100% - 50rem);
        @include space(margin, left, $zero);
    }

    .duck-png-5 {
        width: 340px;
    }
}

.main-container {
    min-height: 100vh !important;
}
