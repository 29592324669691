.main-head-section {
    h1 {
        @include font(map-get($theme-colors, "primary"), $size-two + 0.75, $bold, $font * 3 + 0.25);
        margin-bottom: $one-pixel * 2;
        float: $left;
        width: $percentage * 10;
    }
    h2 {
        @include font(map-get($theme-colors, "grey"), $size-two + 0.187, $bold, $size-two + 0.625);
        padding: 1.125rem $zero $size-quarter * 3;
        float: $left;
        width: $percentage * 10;
    }
    h3 {
        @include font(map-get($theme-colors, "grey"), $size-one + 0.75, $normal, $size-two + 0.25);
        padding: $zero $zero $size-quarter;
        float: $left;
        width: $percentage * 10;
    }
    h4 {
        @include font(map-get($theme-colors, "grey"), $font + 0.125, $normal, $font + 0.375);
        padding: $zero $zero $size-quarter * 3;
        float: $left;
        width: $percentage * 10;
    }
}
