$position-fixed: fixed;
$position-absolute: absolute;
$position-relative: relative;
$zero: 0;
$none: none;
$block: block;
$in-block: inline-block;
$font: 1rem;
$left: left;
$right: right;
$primary-aflac-blue: #0071ad;
$primary-grey: #555;
$primary-light-grey: #f1f3f4;
$primary-white: #fff;
$primary-orange: #f89728;
$primary-aflac-light-blue: #ecfaff;
$alert-danger: #d13719;
$full-percentage: 100%;
$underline: underline;
$auto: auto;
$bg-gradient: #ecfaff;
$flex: flex;
$center: center;

body {
    box-sizing: border-box;
    height: $full-percentage;
    span.blue-text {
        color: $primary-aflac-blue;
    }
    .preview-aflac {
        margin-top: $font * 5;
        img {
            width: $font + 8.75;
            height: $font + 2.125;
        }
        img.client-logo {
            width: $font + 8.75 !important;
            height: $font + 2.125 !important;
            border-left: 1px solid #ccc !important;
            margin: 0 18px 0 19px;
            padding-left: 24px;
            @media screen and (max-width: 37.5rem) {
                width: 140px !important;
                margin: 0 0 0 0 !important;
                height: 56px !important;
            }
        }
        img:first-child {
            border-left: $none;
        }
        img:first-child {
            border-left: $none;
        }
    }
    .preview-heading {
        padding-top: $font + 1.132;

        h1 {
            padding-bottom: $font;
        }
    }
    .preview-content {
        padding-top: 0.875rem;
        p {
            font-size: $font + 0.125;
            color: $primary-grey;
            line-height: $font + 0.75;
            padding-bottom: 0.6875rem;
        }
        a {
            font-size: $font + 0.125;
            color: $primary-grey;
            font-weight: bold;
        }
    }
    .preview-video {
        display: $flex;
        video {
            width: $full-percentage;
            padding-left: $zero;
        }
        .right {
            background: $primary-aflac-blue;
            border-radius: $font / 4;
            padding: $font + 0.25;
            color: $primary-white;
            text-align: $center;
            width: 17.125rem;
            height: 18rem;
            @media screen and (max-width: 37.5rem) {
                height: 18.188rem;
            }
            img {
                padding-top: 0rem;
            }
            p {
                font-size: $font + 0.125;
                font-weight: $light;
                padding-top: $font + 0.75;
                width: 90% !important;
                margin: $auto;
                line-height: $font + 0.5;
                @media screen and (max-width: 37.5rem) {
                    width: 70% !important;
                }
            }
            button {
                border: $none;
                background-color: $primary-white;
                color: $primary-aflac-blue;
                margin-top: $font + 0.7rem;
                font-weight: $semibold;
                font-size: 1.125rem;
                @media screen and (max-width: 37.5rem) {
                    margin-top: 0.5rem;
                }
            }
        }
    }
    .preview-Address {
        ul {
            list-style-type: $none;
            display: $flex;
            padding: $zero;
            li {
                text-align: $center;
                :nth-child(1) {
                    margin: $auto;
                }
            }
            a {
                color: $primary-aflac-blue;
            }
        }
    }
    .preview-ProductInformation {
        margin-right: $zero;
        margin-left: $zero;
        // ---------------------------Non-aflac Tile css
        .non-aflac-position {
            position: absolute;
            bottom: 0px;
        }
        section.non-aflac-tile {
            position: $position-relative;
            // background:$primary-aflac-blue;
            .tile-content {
                width: $full-percentage;
            }
            .description-content {
                display: flex;
                .tile-logo {
                    position: relative;
                    img {
                        top: 4rem !important;
                        max-width: 100px !important;
                        max-height: 140px !important;
                        position: absolute;
                    }
                }
            }

            span {
                // /extra small screen
                @media screen and (max-width: 378px) {
                    height: 7rem;
                    width: 19rem;
                    overflow: auto;
                }
                // /Mobile
                @media screen and (min-width: 378px) and (max-width: 768px) {
                    height: 8rem;
                    width: 19rem;
                    overflow: auto;
                }
                // ipad
                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    height: 7rem;
                    width: 19rem;
                    overflow: auto;
                }
                height: 9rem;
                width: 21rem;
                overflow: auto;
                padding-bottom: 10px;
            }
        }
        .Tile-blue {
            background: $primary-aflac-blue;
            h5,
            a {
                color: #fff !important;
            }
            span {
                color: $primary-white;
            }
        }
        // -----------------------Non Aflac Tiles
        // Start:Plad Product tile
        .plad-product-position {
            position: absolute;
            bottom: 0px;
        }
        .plad-product-material-h5 {
            position: relative !important;
        }
        section.plad-product-tile {
            position: $position-relative;
            // background:$primary-aflac-blue;
            .tile-content {
                width: $full-percentage;
            }
            .description-content {
                display: flex;
                .tile-logo {
                    position: relative;
                    img {
                        top: 4rem !important;
                        max-width: 100px !important;
                        max-height: 140px !important;
                        position: absolute;
                    }
                }
            }

            span {
                // /extra small screen
                @media screen and (max-width: 378px) {
                    height: 7rem;
                    width: 19rem;
                    overflow: auto;
                }
                // /Mobile
                @media screen and (min-width: 378px) and (max-width: 768px) {
                    height: 8rem;
                    width: 19rem;
                    overflow: auto;
                }
                // ipad
                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    height: 7rem;
                    width: 19rem;
                    overflow: auto;
                }
                height: 9rem;
                width: 21rem;
                overflow: auto;
                padding-bottom: 10px;
            }
        }
        // End:Plad Product tile

        div.full-width {
            width: $full-percentage;
        }
        section.preview-Details {
            padding: $font + 0.875 $font + 0.875 $font + 1.875 $font + 0.75;
            color: $primary-grey;
            position: $position-relative;
            h2 {
                margin: $zero;
                font-size: $font + 0.375;
                padding: $zero;
            }
            h4 {
                padding-top: $font + 0.25;
                font-size: $font + 0.375;
                border-bottom: 0.0625rem $solid $primary-grey;
                padding-bottom: 0.3125rem;
                font-weight: 600 !important;
            }
        }
        .preview-tiles {
            display: $flex;
            flex-wrap: wrap;
        }
        h6 {
            font-size: 2.1875rem;
            font-weight: $bold;
            line-height: $font + 1.625rem;
            color: $primary-aflac-blue;
        }
        a.closeIcon {
            float: $right;
            margin: $zero;
            color: $primary-grey;
            font-weight: $semibold;
            border-bottom: $none;
        }
        section.titlesSection {
            height: 261px !important;
            position: relative;
        }
        section {
            display: $flex;
            background: $primary-white;
            border-radius: $font / 4;
            min-height: 13.75rem;
            padding: $zero $font $zero;
            // margin-bottom: $font + 0.875rem;
            opacity: 0.9;
            box-shadow: 0.25rem $zero 0.9375rem #efeeee;
            width: 24.0625rem;
            // height: 249px!important;

            img {
                width: $auto;
            }
            h5 {
                color: $primary-grey;
                font-size: $font + 0.375;
                padding-top: $font;
                font-weight: 600;
                // margin-right: -130px;
                position: $position-absolute;
            }
            a {
                display: $in-block;
                margin-bottom: 0.7rem;
                font-size: $font + 0.125;
                border-bottom: $zero $solid $primary-aflac-blue;
                line-height: $font + 0.25;
                font-weight: $semibold;
                color: $primary-aflac-blue;
                text-decoration: $underline;
            }
            a:hover {
                text-decoration: $none;
            }
            span.head {
                font-weight: $semibold;
            }
            a.linkDetails {
                margin: $zero;
                padding-top: $font;
                padding-bottom: $font;
                font-size: $font + 0.125;
                text-decoration: $underline;
                font-weight: $semibold;
            }
            span {
                font-size: $font + 0.125;
                display: $block;
                color: $primary-grey;
                height: 7.5rem;
                width: 11.875rem;
                overflow: $auto;
                line-height: $font + 0.687;
            }
        }
    }
    .preview-message {
        background-color: #0a8145;
        padding: 0.625rem 0.9375rem;
        font-size: $font + 0.125;
        height: 3.125rem;
        position: $position-fixed;
        width: 100%;
        z-index: 9;
        top: $zero;
        span {
            color: $primary-white;
            font-weight: $semibold;
        }
        .icon.eye-icon {
            fill: $primary-white;
            margin-right: 0.4375rem;
        }
        .eye-icon-left {
            float: left;
            top: 5px;
            position: relative;
        }
    }

    .preview-Footer {
        background-color: #f4f4f4;
        margin-top: 4.125rem;
        padding-top: 2.375rem;
        padding-bottom: 1.32rem;
    }
    .preview-Footer p {
        padding-bottom: $zero;
        font-size: 0.75rem;
    }

    .modal-dialog {
        .modal-content {
            .modal-header {
                h4 {
                    font-size: $font + 0.125;
                    font-weight: $bold;
                    color: $primary-grey;
                }
            }
            .modal-body {
                font-size: $font;
            }
        }
        .employee-footer.modal-footer {
            border-top: 0px !important;
        }
    }
    @media only screen and (max-width: 37.5rem) {
        img.illness-duck {
            right: 1.1rem !important;
        }
        .preview-video {
            display: $block;
        }
        .preview-container {
            padding: $zero $font + 0.25rem;
        }
        .preview-container {
            width: $full-percentage;
        }

        .preview-message-main {
            width: $full-percentage;
            padding-left: 0.5rem;
        }
        .preview-video-content {
            width: $full-percentage !important;
        }
        p.video-content {
            width: 55% !important;
            padding-bottom: $font + 0.25rem;
        }

        .preview-Address ul {
            display: $block;
        }
        .preview-ProductInformation section {
            width: 20.9375rem;
        }

        .preview-ProductInformation section img.life-duck {
            right: $font + 0.5625 !important;
            top: $font + 0.875;
        }
        .preview-ProductInformation section img.cancer-duck {
            right: 3.25rem !important;
        }
        .preview-ProductInformation section img.vision-duck {
            width: 9.5rem;
            height: 11.4375rem;
            right: $font + 0.6875 !important;
            top: $font + 0.25;
        }
        .preview-ProductInformation section img.dental-duck {
            width: 10.875rem;
            height: 10.1875rem;
            top: 2.6875rem;
            right: 2.4375rem !important;
        }
        .preview-ProductInformation section img.hospital-duck {
            width: 9.375rem;
            height: 12.25rem;
            top: $font;
            right: $font !important;
        }
        .preview-ProductInformation section img.critical-duck {
            width: 9.1875rem;
            height: 12.4375rem;
            top: 0.75rem;
            right: $font + 1.8125rem !important;
        }
        .preview-ProductInformation section img.Ascendital-duck {
            width: 10.6875rem;
            height: 11.4375rem;
            top: $font + 1.1875;
            right: $font + 1.875rem !important;
        }
        .preview-ProductInformation section img.disability-duck {
            width: 11.375rem;
            height: 11.625rem;
            top: 1.75rem;
            right: $font * 3 !important;
        }

        .product-data-container {
            width: $full-percentage !important;
        }
    }

    button.btn.btn-secondary.outline.preview-btn-modal {
        width: 18.9375rem !important;
        margin-bottom: 7px;
    }

    @media only screen and (max-width: 47.9375rem) {
        .preview-less {
            padding: $zero;
        }
        .accident-duck {
            right: 2.3rem !important;
        }
    }
    .accident-duck {
        right: 0rem;
    }
    .disability_border {
        border-bottom: 0.5rem $solid #94b7bb;
    }
    .accident_border {
        border-bottom: 0.5rem $solid #aa8066;
    }
    .hospital_border {
        border-bottom: 0.5rem $solid #ff8200;
    }
    .dental_border {
        border-bottom: 0.5rem $solid #69b3e7;
    }
    .illness_border {
        border-bottom: 0.5rem $solid #e1523d;
    }
    .vision_border {
        border-bottom: 0.5rem $solid #485cc7;
    }
    .life_border {
        border-bottom: 0.5rem $solid #f6eb61;
    }
    // .cancer_border {
    //     border-bottom: 0.5rem solid #d43997;
    // }
    //border color change request by client
    .cancer_border {
        border-bottom: 0.5rem solid #1798c2;
    }

    .red {
        border-bottom: 0.5rem $solid red;
    }
}
.lowercase-content {
    text-transform: lowercase !important;
}

.product-data-container {
    // width: 49.75rem !important;
    width: 100% !important;
}

/****** Tiles Section CSS Starts ********/
.preview-ProductInformation section img {
    position: $position-relative;
    z-index: -1;
}
.preview-ProductInformation section img.life-duck {
    right: -0.625rem;
    top: 3rem;
    height: 169px;
}

.preview-ProductInformation section img.cancer-duck {
    right: 1.8rem !important;
}
.preview-ProductInformation section img.vision-duck {
    width: 10.125rem;
    height: 11.4375rem;
    right: $zero !important;
    top: 2.7rem !important;
}
.preview-ProductInformation section img.dental-duck {
    width: 10.875rem;
    height: 10.1875rem;
    top: 2.6875rem;
}
.preview-ProductInformation section img.hospital-duck {
    width: 9.375rem;
    height: 12.25rem;
    top: $font;
    right: $zero !important;
}
.preview-ProductInformation section img.critical-duck {
    width: 9.1875rem;
    height: 12.4375rem;
    top: 0.5rem;
    right: $zero !important;
}
.preview-ProductInformation section img.Ascendital-duck {
    width: 10.6875rem;
    height: 11.4375rem;
    top: 0.75rem;
    right: $zero;
}
.preview-ProductInformation section img.disability-duck {
    width: 11.375rem;
    height: 11.625rem;
    top: $font + 0.25;
    right: 0.825rem;
}

.collapsible {
    background-color: #777;
    color: $primary-white;
    cursor: $pointer;
    padding: $font + 0.125;
    width: $full-percentage;
    border: $none;
    text-align: $left;
    outline: $none;
    font-size: 0.9375rem;
}

// .active,
// .collapsible:hover {
//     background-color: $primary-grey;
// }

.content {
    padding: 0 $font + 0.125;
    display: $none;
    overflow: $hidden;
    background-color: #f1f1f1;
}

.preview-Address ul li:first-child {
    padding-right: $font + 0.25;
    width: 160px;
}

.preview-container {
    width: 49.75rem;
    margin: $zero $auto;
}

.preview-message-main {
    width: 49.75rem;
    margin: $zero $auto;
}

.preview-message-main span {
    display: $block;
}

.preview-message-main .icon.cross {
    fill: $primary-white;
}

.preview-Address h4 {
    font-size: $font + 0.125rem;
    color: $primary-grey;
    font-weight: $normal;
}

.preview-Address h5 {
    font-size: $font + 0.375rem;
    color: $primary-grey;
    font-weight: $normal;
}

.pre-add-content {
    display: inline-flex;
    font-size: $font + 0.125;
    color: $primary-aflac-blue;
}

.up-arrow {
    width: $zero;
    height: $zero;
    border-left: 0.9375rem $solid transparent;
    border-right: 0.9375rem $solid transparent;
    border-bottom: $font + 0.25 $solid $primary-white;
    position: $position-absolute;
    top: -1.25rem;
    left: $font + 0.875;
}

.up-arrow-new {
    width: $zero;
    height: $zero;
    border-left: 0.9375rem $solid transparent;
    border-right: 0.9375rem $solid transparent;
    border-bottom: $font + 0.25 $solid $primary-white;
    position: $position-absolute;
    top: -1.25rem;
    right: $font + 0.875;
    //box-shadow: 0.25rem -3.4375rem 0.9375rem #fdfeff;
}

.icon.calender-icon {
    fill: $primary-white;
    width: $font + 1.25;
    height: $font + 2.75;
    position: $position-relative;
    top: 0.875rem;
}

.preview-btn {
    background-color: $primary-white !important;
    width: 10rem;
    height: 3.125rem;
}

.preview-Address {
    width: $auto;
    display: table;
    margin: $zero $auto;
    padding: 2.5rem 0 1.3rem 0;
}

img.profile-pic {
    width: 3.5625rem;
    height: 3.5625rem;
    border-radius: 50%;
}

.product-data-container p {
    font-size: $font + 0.125;
    padding: 0.5rem 0 0 0;
}

.head {
    font-size: $font + 0.125;
    // font-weight: $semibold;
    font-weight: bold !important;
    line-height: 40px;
}

.modal-footer {
    margin: $zero $auto;
}

.padding-extra {
    padding-right: 0.625rem;
}

.contact-left {
    width: $auto;
    float: $left;
}

.contact-right {
    width: $auto;
    float: $right;
}

.preview-message-main p {
    display: inline;
    padding: 0.0625rem 0 0 0.625rem;
    position: $position-absolute;
    .preview-close {
        width: max-content;
    }
}
.preview-message-main p.preview-close {
    width: max-content;
    padding: 0.0625rem 0 0 0.125rem;
}
.getStarteddialog {
    .modal-body {
        max-height: 70vh !important;
        overflow: auto;
    }
}

@media screen and (max-width: 768px) {
    .getStarteddialog {
        width: 90% !important;
        .modal-body {
            max-height: 50vh !important;
            overflow: auto;
        }
    }
    p.preview-close {
        display: none;
    }
}
.preview-message-main img.eye-icon {
    cursor: pointer;
}
.grid-container {
    display: grid;
    // grid-gap: 10px;
    grid-auto-flow: dense;
}
.grid-item {
    display: flex;
    margin: 0 20px 20px 0 !important;
}
@media screen and (min-width: 37.5rem) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
    .grid-item:nth-child(2n + 2) {
        grid-column: 1 / 3;
    }
}
.hideContainer {
    display: none;
    position: relative;
}

.preview-links {
    display: flex;
    flex-flow: column;
    margin-top: $zero;

    & a {
        :last-child {
            padding-top: 1rem;
            padding-bottom: 0.25rem;
        }

        :visited {
            text-decoration: none;
        }
    }
}
.bc-player-default_default {
    height: 100%;
    width: 100% !important;
}
.videoSection_popu {
    height: 100%;
    height: 85vh !important;
}

.employee-modal-main.modal-content {
    width: 21.4375rem !important;
    margin: 0 auto !important;
}
.label-color {
    color: #0071ad !important;
    padding-top: 12px !important;
}

.preview-video {
    .customvideoHeight {
        height: 300px !important;
    }
}
@media only screen and (max-width: 37.5rem) {
    .contact-left,
    .contact-right {
        float: inherit !important;
    }
    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 2.5rem !important;
        right: 2.1rem !important;
    }

    .footer-links a {
        color: #555;
        font-size: 14px;
        font-weight: 600;
        display: block;
        text-align: left;
        width: 100%;
        padding: 6px 0 10px 0 !important;
        margin: 0;
    }
    .privacy-links {
        padding: 0 0 10px 0;
    }
    .preview-Address ul li:first-child {
        width: $auto;
    }
}
@media screen and (orientation: portrait) {
    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 0.8rem !important;
        right: 0rem !important;
    }
}

@media screen and (orientation: landscape) {
    .popup__close:link,
    .popup__close:visited {
        z-index: 99999 !important;
        top: 0rem !important;
        right: 1.5rem;
        // right: 6.5rem !important;
    }
}

.employee-content-section {
    display: ruby-base !important;
}

.footer-links {
    overflow: hidden;
    padding: 0px;
}

.footer-links a {
    color: #555;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 0 15px;
}

.footer-links a:hover {
    color: #4c4c4c;
}

p.AflacFooterContent {
    text-transform: uppercase;
}

#iframe1 {
    border-radius: 0.4rem !important;
    border: none;
}
div#vjs_video_3 {
    .vjs-poster {
        cursor: default !important;
    }
}

div.preview-less {
    img {
        width: max-content !important;
        height: max-content !important;
        top: 2rem !important;
        // right: 0 !important;
    }
    .preview-aflac {
        img {
            width: 9.75rem !important;
            height: 3.125rem !important;
        }
    }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
    body .preview-ProductInformation section {
        width: 23.1375rem !important;
    }

    body .preview-ProductInformation section img.disability-duck {
        right: 0rem !important;
        top: 2.5rem !important;
    }

    body .accident-duck {
        right: 0rem !important;
        top: 2.8rem !important;
    }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    img.accident-duck {
        right: 1.1rem !important;
    }
    img.life-duck {
        right: 0 !important;
    }
    img.dental-duck {
        right: 1.2rem !important;
    }
    img.disability-duck {
        right: 1.1rem !important;
    }
    .preview-container {
        width: auto;
        margin: $zero $auto;
    }

    .preview-message-main {
        width: 45.75rem;
    }

    body .preview-container {
        padding: 0 20px;
    }

    body .preview-ProductInformation section {
        width: 22.0625rem;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
    .preview-container {
        width: 49.5rem;
        //    margin: $zero $auto;
    }
}

body .preview-ProductInformation section .full-width .closeIcon img {
    top: 0 !important;
}

/**** Suggestive Selling Toggle Text *****/
p.toggle-text {
    color: #555;
    position: relative;
    top: -4px;
}

p.toggle-text a {
    text-decoration: underline;
}

/**** Suggestive Selling Toggle Text *****/

.center-preview-text {
    position: absolute;
    width: inherit;
    /* text-align: center; */
    @media screen and (min-width: 48.5rem) {
        left: 40%;
    }

    color: #fff;
}

.close_button {
    @media screen and (min-width: 48.5rem) {
        position: absolute;
        right: 200px;
    }
}
img.aflaclogo {
    @media screen and (max-width: 37.5rem) {
        padding-right: 22px !important;
    }
}
div.preview-less .preview-aflac img.client-logo {
    width: auto !important;
    @media screen and (max-device-width: 37.5rem) {
        max-width: 9.75rem !important;
        right: 150px;
    }
}
div.preview-less .preview-aflac .selectWrapper {
    @media screen and (max-device-width: 37.5rem) {
        width: auto;
        min-width: 148px;
    }
}

.selectWrapper {
    border-radius: 10px;
    margin-top: 10px;
    border: 1.4px solid #0071ad;
    float: right;
    width: 160px;
    height: 35px;
}
.selectWrapper .vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
    transform: scale(0.8);
}
.selectWrapper .vs__selected {
    font-weight: bold;
    color: #767171;
}

.selectWrapper .vs--single .vs__selected {
    background-color: transparent;
    border-color: transparent;
    margin-top: -5px;
    margin-left: 14px;
    font-size: 14px;
}
.selectWrapper .vs__open-indicator {
    fill: #848282;
    position: relative;
    top: 0.15rem;
}
.selectWrapper .vs__actions {
    border-bottom: 0.063rem solid #effaff;
}
.selectWrapper button.vs__clear {
    display: none;
}
.selectBox {
    width: 140px;
    height: 35px;
    border: 0px;
    outline: none;
    padding: 8px;
    color: #555;
}
div.preview-less .preview-aflac .btnEnglish {
    border: 2px solid #0071ad;
    height: 33px;
    padding-top: 3px;
}
div.preview-less .preview-aflac .btnEnglish img {
    width: 19px !important;
    height: 19px !important;
}
div.preview-less .preview-aflac .btnSpanish {
    border: 2px solid #0071ad;
    height: 33px;
    padding-top: 3px;
}
div.preview-less .preview-aflac .btnSpanish img {
    width: 19px !important;
    height: 19px !important;
}
div.preview-less .preview-aflac .clsLanguage-Container {
    float: right;
    // background-color: #0573ae;
    border-radius: 0.25rem;
    display: none;
}

.contact-mt {
    margin-top: 1.25rem;
}
p.video-content-new {
    padding: 0 !important;
    @media screen and (max-device-width: 37.5rem) {
        position: relative;
        top: -18px;
    }
}

.rate-sheet-bdr {
    border-top: 0.0625rem solid #555;
}

/*Non-Aflac Disclaimer Model*/
#nonAflacmyModal,
#pladProductModal {
    .modal-content.employee-modal-main {
        width: 532px !important;
        height: 330px !important;
        @media screen and (max-width: 37.5rem) {
            width: 100% !important;
            margin: 0px !important;
            height: 360px !important;
        }
    }
    .modal-header {
        padding: 1.87rem 1.5rem !important;
    }
    .modal-body {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        overflow: auto;
        font-size: 1.125rem;
    }
    .employee-footer.modal-footer .outline.preview-btn-modal {
        width: 482px !important;
        margin-bottom: 7px;
        max-width: 482px;
        @media screen and (max-width: 37.5rem) {
            max-width: 18.9375rem !important;
        }
    }
    h4.modal-title {
        font-size: 1.25rem !important;
        font-weight: 600 !important;
    }
}
div.emp-video img {
    max-width: 100% !important;
    max-height: 100% !important;
}

.intro-image-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#myModal {
    .modal-content.employee-modal-main {
        width: 532px !important;
        height: 330px !important;
        @media screen and (max-width: 37.5rem) {
            width: 100% !important;
            margin: 0px !important;
            height: 360px !important;
        }
    }
    .modal-header {
        padding: 1.87rem 1.5rem !important;
    }
    .modal-body {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        overflow: auto;
        font-size: 1.125rem;
    }
}
.employee-title-margin {
    margin-top: 66px !important;
}
.non-aflac-material-h5 {
    position: relative !important;
}
#empPreview div.preview-less img {
    top: 3.7rem !important;
}

#empPreview div.preview-less .closeIcon img {
    top: 0px !important;
}

.tileScroll::-webkit-scrollbar {
    width: 0.4em;

    scrollbar-width: 0.4em;
}

/*
.tileScroll {
    scrollbar-color: #c7c4c4 #7a7e7a;

    scrollbar-width: thin;
}
*/
.tileScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.tileScroll::-webkit-scrollbar-thumb {
    background-color: #e2e7ec;

    outline: 1px solid #acafb1;
}
#empPreview.contentSpanish section.right.preview-video-content p {
    line-height: 1.3rem;
    padding-top: 0.75rem;
}
.contentSpanish .preview-ProductInformation section h5 {
    color: #555;
    font-size: 1.215rem;
    padding-top: 1rem;
    font-weight: 600;
    position: absolute;
    margin-top: -5px;
}
.contentSpanish .preview-ProductInformation section span {
    font-size: 1.125rem;
    display: block;
    color: #555;
    height: 7.5rem;
    width: 11.875rem;
    overflow: auto;
    line-height: 1.687rem;
    margin-bottom: 7px;
}
.dummyCss {
    color: #0071ad;
}
