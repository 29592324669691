$in-block: inline-block;
.profile-image {
    @include space(margin, bottom, $half);
    float: $left;
    width: $percentage * 8;
    .profile-image__file {
        display: map-get($display, "none");
    }
    .profile-image__label {
        pointer-events: $all;
        position: map-get($position, "relative");
        top: -1.3125rem;
    }
    .profile-image__image {
        cursor: $pointer;
        vertical-align: $text-bottom;
        padding: 0.625rem $zero;
        position: map-get($position, "relative");
        top: 0.6875rem;
        left: $zero;
    }
    .profile-image__text {
        @include space(margin, left, $half);
        @include font(map-get($theme-colors, "primary"), $font + 0.125, $normal, $font + 0.75);
        border-bottom: $one-pixel $solid map-get($theme-colors, "primary");
        cursor: $pointer;
        padding-bottom: $zero;
    }
    .profile-image__text p {
        display: inline;
        padding: 0.6875rem $zero;
    }
}

.profile__fileName {
    @include space(padding, left, $small);
    float: $left;
    font-style: $italic;
    font-weight: $bold;
    width: $percentage * 10;
}

.autolist-email {
    @include border-radius(0.188rem);
    @include space(margin, bottom, $zero);
    @include space(padding, all, $zero);
    box-shadow: $shadow;
    position: map-get($position, "relative");
    li {
        @include space(padding, all, $half);
        color: map-get($theme-colors, "grey");
        cursor: $pointer;
        list-style: $none;
    }
}
.save-photo-section {
    /*display: $in-block;*/
    /*background: #fff;
    width: 300px;
    min-height: 105px;*/
    min-height: 90px;
    /*border-radius: 0.5rem;
    border: 1px solid #bebebe;
    padding-top: 16px;*/
    width: 300px;
    text-align: center;
    margin-top: -20px;
}
img.profile-photo {
    width: auto;
    height: 58px;
    max-width: 70px;
    margin: 0 auto;
}

@media screen and (max-width: 37.5rem) {
    .save-photo-section {
        /*display: $in-block;
        background: #fff;
        width: 330px;
        */
    }
}

.circular_image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #bebebe;
    overflow: hidden;
    background-color: #0071ad;
    /* commented for demo
    float: left;
    margin-left: 125px;
    margin-top: 20px;
    */

    /*for demo*/
    display: inline-block;
    vertical-align: middle;
}
.circular_image img {
    width: 100%;
}

.error-wrapper {
    background: #fff;
    width: 300px;
    min-height: 105px;
    border-radius: 0.5rem;
    border: 1px solid #bebebe;
    padding-top: 16px;
}
.large-sucess-hack img {
    width: auto;
    height: 14px;
}

.error-section-profile-photo {
    color: #555;
    margin-bottom: 1rem;
    font-style: italic;
    padding-top: 5px;
}
