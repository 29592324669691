@mixin border($style, $color, $sides) {
    @if ($style != "") {
        @if ($sides == "") {
            border: $style $color;
        } @else {
            @each $side in $sides {
                @if ($side == "top" or $side == "right" or $side == "bottom" or $side == "left") {
                    border-#{$side}: $style $color;
                }
            }
        }
    }
}

@mixin border-radius($property) {
    border-radius: $property;
}

@mixin font($color, $size, $weight, $height) {
    color: $color;
    font-size: $size;
    font-weight: $weight;
    line-height: $height;
}

@mixin align-items($property) {
    -moz-align-items: $property;
    -ms-align-items: $property;
    -webkit-align-items: $property;
    align-items: $property;
}

@mixin box-align($property) {
    -moz-box-align: $property;
    -ms-box-align: $property;
    -webkit-box-align: $property;
    box-align: $property;
}

@mixin flex-direction($property) {
    -moz-flex-direction: $property;
    -ms-flex-direction: $property;
    -webkit-flex-direction: $property;
    flex-direction: $property;
}

@mixin flex-wrap($property) {
    -moz-flex-wrap: $property;
    -ms-flex-wrap: $property;
    -webkit-flex-wrap: $property;
    flex-wrap: $property;
}

@mixin box-sizing($property) {
    -moz-box-sizing: $property;
    -ms-box-sizing: $property;
    -webkit-box-sizing: $property;
    box-sizing: $property;
}

@mixin animation($type, $property) {
    -moz-animation: $type $property ease;
    -ms-animation: $type $property ease;
    -webkit-animation: $type $property ease;
    animation: $type $property ease;
}

@mixin transform($transform) {
    -moz-transform: $transform;
    -ms-transform: $transform;
    -webkit-transform: $transform;
    transform: $transform;
}

// Rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// Skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin translatex($y) {
    @include transform(translatex($x));
}

@mixin translatex($y) {
    @include transform(translateY($y));
}

@mixin transition($property) {
    -moz-transition: $property ease all;
    -ms-transition: $property ease all;
    -webkit-transition: $property ease all;
    transition: $property ease all;
}

@mixin primary-btn($max-width) {
    @include space(padding, all, $zero);
    background-color: map-get($theme-colors, "primary");
    border-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    font-size: $font + 0.125rem;
    line-height: 2.75rem;
    height: $size-three + 0.125;
    max-width: $max-width;
    width: $percentage * 10;
}

@mixin secondary-btn($max-width) {
    @include space(padding, all, $zero);
    background-color: map-get($theme-colors, "white");
    border: $one-pixel * 2 $solid map-get($theme-colors, "primary");
    color: map-get($theme-colors, "primary") !important;
    font-size: $font + 0.125rem;
    line-height: 2.75rem;
    height: $size-three + 0.125;
    max-width: $max-width;
    width: $percentage * 10;
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
