.agent-search-heading {
    padding-bottom: 2.875rem;
    h1 {
        width: $percentage * 10;
        display: block;
    }
    a {
        @include space(margin, top, $mediumhlf);
        color: #0071ad;
        text-decoration: $underline;
    }
}

.disable-icon {
    border-bottom: 0.125rem solid map-get($theme-colors, "medium-grey");
    width: 0.75rem;
    height: 0.125rem;
    display: map-get($display, "inline-block");
}

.success-icon {
    display: map-get($display, "inline-block");
}

.font-bold {
    font-weight: bold;
}

.date-50 {
    width: 3rem;
    float: $left;
    position: map-get($position, "relative");
    text-align: right;
    @include space(margin, right, $small);
}

.date-bold {
    font-weight: bold;
    font-size: 0.875rem;
    display: map-get($display, "block");
}

.date-year,
.first-end-date {
    display: map-get($display, "block");
    font-size: 0.75rem;
    padding: 0 0 0 1rem;
}

.date-sign {
    font-weight: bold;
    position: map-get($position, "absolute");
    right: -0.875rem;
    top: -0.25rem;
}

.reassign-btn-section {
    width: 220px;
    float: right;
    :disabled {
        color: #171717;
        background-color: #f1f3f4 !important;
        border-color: #f1f3f4 !important;
        font-weight: bold;
    }
}

.agent-search-container {
    display: inline-block;
}

.reassign-btn-section button.btn.btn-primary {
    padding: 0 1rem;
    margin-bottom: 1px;
}

.termed-first {
    width: 210px;
}

.termed-reassigned {
    width: 210px;
}

.select-all-section label {
    color: map-get($theme-colors, "grey");
}

.font-18 {
    font-size: 1.125rem !important;
}
img.loader-animation {
    width: 80px;
    position: relative;
    top: 12px;
    left: 125px;
}

// Large screen Desktop Ipad and large
@media only screen and (min-width: 50rem) {
    img.loader-animation {
        top: 12px;
    }
    .showMobile {
        display: none;
    }
    .showDesktop {
        display: block;
    }

    .first-site {
        font-size: 0.75rem;
        font-weight: 400;
    }

    .l-height {
        line-height: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    .admin-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 1.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                width: 6.875rem;
            }

            .termed-padd {
                padding-left: 25px;
            }
            //  th:first-child {
            //    width: .75rem;
            // }
            // th:nth-child(2) {
            //   width: 3rem;
            // }
            // th:nth-child(3) {
            //   width: 3.5rem;
            // }
            // th:nth-child(4),
            // th.text-center {
            //   width: 1.5rem;
            // }
            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                border-bottom: 1px solid #dee2e6 !important;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

//Small Screen

@media only screen and (max-width: 50rem) {
    img.loader-animation {
        top: 12px;
    }
    .checkbox-mob-padd {
        margin-top: 40px;
    }
    .displayBlock {
        display: block;
    }
    .date-column {
        min-width: 130px !important;
    }
    .showMobile {
        display: block;
    }
    .showDesktop {
        display: none;
    }
    .reassign-btn-section {
        width: 220px;
        display: block;
        padding: 0 0 8px 0 !important;
        float: inherit;
        margin-top: 0.62rem;
    }
    .admin-data-table {
        @include space(padding, all, $zero);
        display: map-get($display, "block") !important;
        margin: 2.5rem $zero $zero $zero;
        width: $percentage * 10;
        float: $left;
        overflow: auto;
        table {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            width: $percentage * 10;
            th {
                padding: 0 0.5rem 0.5rem 0;
                font-weight: 400;
                min-width: 60px;
            }

            th:last-child {
                @include space(padding, right, $zero);
            }
            td {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
            }
            td:first-child {
                color: map-get($theme-colors, "grey");
                font-size: $font + 0.125;
                padding: 1rem 0;
            }
        }
    }
}

// .sorting-arrow-down {
//     background: url(../assets/images/account-arrow-down.png) 5px 5px no-repeat;
//     display: map-get($display, "inline-block");
//     width: 20px;
//     cursor: pointer;
// }

// .sorting-arrow-up {
//     background: url(../assets/images/account-arrow-up.png) 4px 5px no-repeat;
//     display: map-get($display, "inline-block");
//     width: 20px;
//     cursor: pointer;
// }

/******* Admin Search Section *******/
.agent-search-container input.form-control {
    height: 44px;
    border: 1px solid #636466;
    border-radius: 2px;
}

.agent-search-container button.btn.btn-secondary {
    height: 44px;
    border: 1px solid #636466;
    padding: 0 10px;
    background-color: gray;
    border-radius: 2px;
}
.agent-search-container button.btn.btn-secondary.in {
    background-color: #0071ad !important;
}
.searchicon {
    display: none;
}

.searchicon.in {
    display: block !important;
}

.input-group-append img {
    top: 0px;
    position: relative;
    left: 1px;
}

/*
          ----------------------------------------------
                              CUSTOM CHECKBOX
          ----------------------------------------------
*/
/* Base for label styling */

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 48px !important;
    cursor: pointer;
    width: $auto;
    line-height: 1.4rem;
    padding: 0.6875rem $zero;
}
table {
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 15px !important;
        cursor: pointer;
        width: $auto;
        line-height: 1.4rem;
    }
}

/* checkbox aspect */

[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 1px;
    top: 7px;
    width: 30px;
    height: 30px;
    background: #0071ad;
    border-radius: $zero;
}

[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 1px;
    top: 7px;
    width: 30px;
    height: 30px;
    border: 2px solid $primary-aflac-blue;
    background: transparent;
    border-radius: $zero;
}

/* checked mark aspect */

[type="checkbox"]:checked + label:after {
    content: " ";
    position: absolute;
    top: 0.6875rem !important;
    left: 0.6875rem !important;
    font-size: 20px;
    color: $primary-white;
    color: $primary-white;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

[type="checkbox"]:not(:checked) + label:after {
    // content: '✔';
    position: absolute;
    top: 0.6875rem !important;
    left: 0.6875rem !important;
    font-size: 20px;
    color: $primary-white;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

/* checked mark aspect changes */

[type="checkbox"]:not(:checked) + label:after {
    opacity: 1;
    transform: scale(1);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* accessibility */

[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    outline: none !important;
}

/*
          ----------------------------------------------
                              CUSTOM RADIO
          ----------------------------------------------
*/
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
    position: relative;
    padding-left: 50px !important;
    cursor: pointer;
    width: 100%;
}

/* checkbox aspect */

[type="radio"]:checked + label:before {
    content: "";
    position: absolute;
    left: 6px !important;
    top: -5px;
    width: 30px;
    height: 30px;
    background: #0071ad;
    border-radius: 50% !important;
}

[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 6px;
    top: -4px;
    width: 30px;
    height: 30px;
    border: 2px solid $primary-aflac-blue;
    background: transparent;
    border-radius: 50%;
}

/* checked mark aspect */

[type="radio"]:checked + label:after {
    content: " ";
    position: absolute;
    top: 0px !important;
    left: 16px !important;
    font-size: 20px;
    color: $primary-white;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

[type="radio"]:not(:checked) + label:after {
    // content: '✔';
    position: absolute;
    top: -11px !important;
    left: 6px !important;
    font-size: 20px;
    color: #fff;
    transition: all 0.2s;
    border-right: 0.125em #fff $solid;
    border-bottom: 0.125em #fff $solid;
    width: 0.625rem;
    height: 1.0625rem;
    transform: rotate(45deg) !important;
}

/* checked mark aspect changes */

[type="radio"]:not(:checked) + label:after {
    opacity: 1;
    transform: scale(1);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* accessibility */

[type="radio"]:checked:focus + label:before,
[type="radio"]:not(:checked):focus + label:before {
    outline: none !important;
}

/* Toggel on/off checkbox CSS start */

.onoffswitch [type="checkbox"]:checked + label:before {
    content: "";
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    pointer-events: all;
    border-radius: 0.5rem;
}
.onoffswitch [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 7px;
    top: -3px;
    width: 32px;
    height: 16px;
    background: #0071ad;
    border-radius: 34px !important;
}
.onoffswitch [type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 7px;
    top: -3px;
    width: 32px;
    height: 16px;
    border: 2px solid #ccc;
    background: #ccc;
    border-radius: 34px;
}
.onoffswitch [type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    top: 0px !important;
    left: 13px !important;
    height: 0.625rem !important;
    width: 0.625rem !important;
    font-size: 20px;
    color: #fff;
    transition: all 0.2s;
    background: #fff;
    // transform: translateX(0.75rem)!important;
}
.onoffswitch [type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: 0px !important;
    left: 13px !important;
    height: 0.625rem !important;
    width: 0.625rem !important;
    font-size: 20px;
    color: #fff;
    transition: all 0.2s;
    pointer-events: all;
    transform: translateX(0.75rem) !important;
}

/* Toggel on/off checkbox CSS end */
.sitelist_modal {
    width: 343px;
    height: 228px;
    .btn160 {
        width: 10rem;
        background: transparent !important;
        color: #0071ad !important;
    }
}
#agent-search {
    .modal-header {
        border: none;
    }
    .modal-footer {
        margin-right: 0;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    #closemodalm {
        width: 102px;
    }
    h5.modal-title {
        padding-left: 16px !important;
        padding-top: 16px;
        font-size: 1.125rem;
    }

    .success-outline {
        margin-bottom: -102px;
    }
    @media only screen and (min-width: 37.5rem) {
        .message-container p {
            margin-left: 6.625rem !important;
        }
        .success-outline {
            margin-bottom: -112px;
        }
        .alert-dismissible .close {
            right: 116px !important;
        }
    }
}
.onoffswitch {
    padding-left: 0px;
}
.radio-width-reassign {
    width: 4rem !important;
}
.delete-site-icon {
    margin-top: 18px !important;
    cursor: pointer !important;
}

.editsite_modal {
    width: 943px;
    height: 228px;
    .btn160 {
        width: 10rem;
        background: transparent !important;
        color: #0071ad !important;
    }
}

.agent-edit-popup {
    z-index: 9 !important;
    h5.modal-title {
        padding-top: 4px !important;
    }
    .site-image-declaimer {
        font-size: 12px;
        color: rgb(122, 122, 122);
        @media screen and (max-width: 37.5rem) {
            padding-bottom: 10px;
        }
    }
    .site-image-heading {
        color: #212529;
        font-size: 16px;
    }
    .modal-header {
        button {
            padding: 0.9rem 0 0 0 !important;
        }
    }
    .image-confirmation-section {
        @media screen and (max-width: 37.5rem) {
            width: 100% !important;
            padding: 0 !important;
        }
    }

    .modal-dialog {
        @media screen and (max-width: 37.5rem) {
            width: 90% !important;
        }
        width: 60% !important;
        .modal-header {
            border-bottom: 1px solid rgb(37, 37, 37) !important;
        }
    }
    .content-seciton {
        padding: 1rem !important;
    }

    input[type="button"] {
        max-width: 90px !important;
        font-size: 14px !important;
        line-height: 1px !important;
        height: 40px !important;
    }
    input[type="button"]:disabled,
    input[type="button"][disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }

    .save-site-image-section {
        display: inline-block;
        background: #fff;
        width: 300px;
        border-radius: 0.5rem;
        border: 1px solid #bebebe;
        padding-top: 5px;
        text-align: center;
        @media screen and (max-width: 37.5rem) {
            width: 100% !important;
        }
    }

    .error-section-site-image {
        color: #555;
        margin-bottom: 5px;
        font-style: italic;
        padding-top: 5px;
    }
    .save-site-image-container {
        margin-bottom: 1.45rem;
    }
    .admin-selection-data-table table th:nth-child(7) {
        width: 3.2rem !important;
    }
    p.image-error {
        margin-top: 0px;
        margin-bottom: 8px;
    }
    .large-sucess-hack {
        padding: 0 0 0px 0;
    }

    .invalid-introduction {
        font-size: 14px;
        color: rgb(168, 54, 54);
        padding-left: 10px;
    }
    .intro-success-msg {
        font-size: 14px;
        color: rgb(52, 148, 52);
        padding-left: 10px;
    }
    img.intro-site-image {
        width: auto;
        height: 58px;
        max-width: 70%;
        margin: 0 auto;
        padding-bottom: 5px;
    }
    .site-add-icon {
        float: left;
        padding: 5px;
    }
    .site-upload-label {
        width: 145px;
        margin-top: 5px;
        span {
            font-size: 14px;
            color: #0071ad;
            margin-top: 7px;
            margin-bottom: 0;
            cursor: pointer;
        }
    }
    .upload-file-hide {
        display: none;
    }
}
.modal-mask {
    min-height: 100% !important;
}
@media only screen and (max-width: 50rem) {
    span.agent-action-onoff {
        width: 96px !important;
        right: 2.2rem !important;
    }
}
.delete-th {
    right: 2.8rem !important;
}
.edit-th {
    right: 1.2rem !important;
}

.delete-td {
    width: 29px !important;
}
.edit-agent {
    margin-top: 10px !important;
}

.overflow-y {
    overflow-y: scroll;
    max-height: 501px;
}
.get-started {
    margin-top: 15px;
}
// to hide TinyMCE domain registeration warning
/*
.mce-notification,
.tox-notifications-container {
    display: none !important;
}
*/
.noRecordFound {
    display: flex;
    align-items: flex-start;
    padding-left: 100px;
}
