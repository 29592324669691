input::placeholder {
    color: $transparent;
}

input:focus::placeholder {
    color: map-get($theme-colors, "medium-grey");
}

.content-wrapper {
    .floating-label {
        float: $left;
        @include space(margin, bottom, $small);
        max-width: $max-width + 3;
        position: map-get($position, "relative");
        width: $percentage * 10;
        .date-field {
            float: $left;
            max-width: 10rem;
            width: $percentage * 5;
        }
        .date-field:first-of-type {
            margin-right: 0.938rem;
        }

        label {
            @include transition(0.2s);
            color: map-get($theme-colors, "grey");
            font-size: $font + 0.125;
            font-weight: $normal;
            left: $zero;
            @include space(margin, bottom, $zero);
            position: map-get($position, "absolute");
            top: $size-quarter * 3;
            pointer-events: $none;
        }
        label.is-invalid {
            color: #d13719 !important; //map-get($theme-colors, "danger") !important;
        }

        input[type="text"],
        input[type="tel"],
        input[type="color"],
        input[type="email"],
        input[type="date"],
        input[type="number"],
        input[type="search"],
        input[type="password"] {
            @include border-radius($zero);
            @include space(padding, all, $zero);
            background: $transparent;
            @include border($zero $solid, map-get($theme-colors, "medium-grey"), "");
            @include border(0.063rem solid, map-get($theme-colors, "medium-grey"), bottom);
            box-shadow: $none;
            color: map-get($theme-colors, "grey");
            font-size: $font + 0.125;
            height: $size-two + 0.8;
            line-height: $size-one + 0.625;
            min-height: $size-one + 0.625;
            // @include space(padding, top, $quarter);
            padding-top: 0.15rem;
            width: $percentage * 10;
            z-index: 2;
        }
        .floating-input:focus,
        .floating-select:focus {
            border-bottom: $one-pixel * 2 $solid map-get($theme-colors, "primary");
            outline: $none;
        }
        .floating-textarea {
            max-height: 16.25rem;
            min-height: 1.875rem;
            overflow: $hidden;
            overflow-x: $hidden;
        }
        .floating-input:focus ~ label,
        .floating-input:not(:placeholder-shown) ~ label,
        .floating-select:focus ~ label,
        .floating-select:not([value=""]):valid ~ label {
            color: map-get($theme-colors, "grey");
            font-size: $font - 0.25;
            left: $zero;
            top: -$one-pixel * 3;
            line-height: $font - 0.25;
        }
        /* active state */
        .floating-input:focus ~ .bar:before,
        .floating-input:focus ~ .bar:after,
        .floating-select:focus ~ .bar:before,
        .floating-select:focus ~ .bar:after {
            width: $percentage * 10/2;
        }
        /* active state */
        .floating-input:focus ~ .highlight,
        .floating-select:focus ~ .highlight {
            @include animation(inputHighlighter, 0.3s);
        }
    }
    span.error-icon {
        left: -$size-two + 0.375;
        position: map-get($position, "relative");
        img {
            position: map-get($position, "absolute");
            top: -$size-quarter;
        }
    }
    .custom-list {
        .error-icon {
            img {
                left: -1.375rem;
                top: 0.75rem;
            }
        }
    }
    .date-field {
        .error-icon {
            img {
                top: 0.75rem;
                left: -1.375rem;
            }
        }
    }
    .invalid-feedback {
        color: #d13719; //map-get($theme-colors, "danger");
        font-size: $size-quarter * 3;
        line-height: 0.93rem;
    }
    .input-group-addon {
        position: map-get($position, "absolute");
        right: $zero;
        top: 0.625rem;
    }
    .custom-checkbox {
        position: map-get($position, "relative");
        z-index: $one;
        display: map-get($display, "block");
        min-height: 2.5rem;
        @include space(padding, left, $large);
        .custom-control-label {
            position: map-get($position, "relative");
            @include space(margin, bottom, $zero);
            vertical-align: top;
        }
        .custom-control-input {
            top: 0.875rem;
            height: 2rem;
            width: 2rem;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: map-get($theme-colors, "primary") !important;
            border-color: map-get($theme-colors, "primary") !important;
        }
        .custom-control-label::before {
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            position: map-get($position, "absolute");
            top: $zero;
            left: -3rem;
            display: map-get($display, "block");
            width: 1.875rem;
            height: 1.875rem;
            pointer-events: none;
            content: "";
            background-color: $transparent;
            @include border(0.063rem $solid, map-get($theme-colors, "primary"), "");
            @include border-radius($zero);
        }
        .custom-control-label::after {
            position: map-get($position, "absolute");
            top: 0;
            left: -3rem;
            display: map-get($display, "block");
            width: 1.875rem;
            height: 1.875rem;
            content: "";
            background: no-repeat 50%/50% 50%;
            @include border-radius($zero);
        }
    }
}
