.modal-mask {
    background-color: rgba(0, 0, 0, 0.5);
    height: $percentage * 10;
    left: 0;
    position: map-get($position, "fixed");
    top: 0;
    transition: opacity 0.3s ease;
    width: $percentage * 10;
    z-index: $one * 9998;
    .modal-wrapper {
        display: map-get($display, "table-cell");
        vertical-align: middle;
        .modal-sm,
        .modal-dialog {
            @include space(margin, right, $auto);
            // width: 21.5rem;
            .modal-header.border-zero {
                @include border($zero $solid, map-get($theme-colors, "medium-grey"), "bottom");
            }
            .modal-header {
                padding: 0.5rem 1rem 0.4rem 1rem !important;
                .close {
                    padding: 1.3rem 0 0 0;
                    @include space(margin, right, $zero);
                    opacity: 1;
                }
                .modal-title {
                    color: map-get($theme-colors, "grey");
                }
                h3.modal-title {
                    @include font(map-get($theme-colors, "grey"), $font + 0.75, $normal, $size-two + 0.25);
                    width: calc(100% - 2rem) !important;
                }
            }
            .modal-body {
                p.primary-text {
                    color: map-get($theme-colors, "primary");
                }
                a {
                    display: map-get($display, "block");
                    font-size: 1rem;
                    overflow: $hidden;
                    text-overflow: $ellipsis;
                    white-space: $nowrap;
                }
            }
            .modal-footer {
                @include border($zero $solid, map-get($theme-colors, "medium-grey"), "top");
                padding: $zero 0.938rem 0.938rem 0.938rem;
            }
        }
    }
}

@media only screen and (max-width: 37.5rem) {
    .modal-body {
        p {
            @include space(margin, all, $zero);
            @include space(padding, all, $zero);
            font-size: ($font + 0.125) rem;
        }
        a {
            overflow: $hidden;
            text-overflow: $ellipsis;
            white-space: $nowrap;
        }
    }
    .modal-backdrop.in {
        display: map-get($display, "block") !important;
    }
    .modal-backdrop {
        background-color: map-get($theme-colors, "black");
        bottom: $zero;
        left: $zero;
        position: map-get($position, "fixed");
        right: 5.625rem !important;
        top: 3.75rem !important;
        z-index: $one * 1040;
    }
}
