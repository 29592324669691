.popup {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: map-get($theme-colors, "popup-grey");
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &__content {
        @include absCenter;
        height: 89%;
        width: 88%;
        top: 50%;
        left: 50%;
        border-radius: 3px;
        display: table;
        overflow: hidden;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.25);
        transition: all 0.5s 0.2s;
    }

    &__video {
        //
        position: absolute;
        z-index: 0;
        background-size: 100% 100%;
        top: 3rem;

        min-width: 100%;
        min-height: 95%;
        width: auto;
        height: auto;
    }

    //Open states
    &:target {
        opacity: 1;
        visibility: visible;
    }

    &:target &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__close {
        &:link,
        &:visited {
            color: grey;
            position: absolute;
            top: 3.5rem;
            right: 3.5rem;
            font-size: 3rem;
            text-decoration: none;
            display: inline-block;
            transition: all 0.2s;
            line-height: 1;
        }

        &:hover {
            color: map-get($theme-colors, "primary") !important;
        }
    }
}

// -------------
.popup {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: map-get($theme-colors, "popup-grey");
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    &__content {
        @include absCenter;
        height: 89%;
        width: 88%;
        top: 50%;
        left: 50%;
        border-radius: 3px;
        display: table;
        overflow: hidden;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.25);
        transition: all 0.5s 0.2s;
    }

    &__video {
        //
        position: absolute;
        z-index: 0;
        background-size: 100% 100%;
        top: 3rem;

        min-width: 100%;
        min-height: 95%;
        width: auto;
        height: auto;
    }

    .in &__content {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__close {
        &:link,
        &:visited {
            color: grey;
            position: absolute;
            top: 3.5rem;
            right: 3.5rem;
            font-size: 3rem;
            text-decoration: none;
            display: inline-block;
            transition: all 0.2s;
            line-height: 1;
        }

        &:hover {
            color: map-get($theme-colors, "primary") !important;
        }
    }
}

.in {
    opacity: 1;
    visibility: visible;
}
//Open states
.in {
    opacity: 1;
    visibility: visible;
}
