.report-container {
    margin-top: 108px;
    padding: 0;
}
.report-table {
    margin: 30px 0;
    table,
    td {
        border: 1px solid #555;
        padding: 5px 10px;
    }
    table {
        width: 100%;
    }
}

.report-table tbody th {
    padding: 5px 10px;
    background: #ebebeb;
}

.main-head-section h2 {
    color: #0071ad;
}
