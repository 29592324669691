.message-container {
    @include border-radius($zero);
    //@include space(margin, top, 60);
    margin-top: 3.75rem;
    background: map-get($theme-colors, "white");
    width: $percentage * 10;
    p {
        font-size: $size-one + 0.125;
        line-height: $size-one + 0.5;
        margin: $size-quarter * 3 $zero $size-quarter * 3 $width * 2;
        width: 85%;
    }
    .success-msg {
        color: map-get($theme-colors, "success");
    }
    .error-msg {
        color: map-get($theme-colors, "danger");
    }
}

.error-outline {
    @include border(0.125rem $solid, map-get($theme-colors, "danger"), "bottom");
    @include border(0.125rem $solid, map-get($theme-colors, "danger"), "top");
}

.success-outline {
    @include border(0.125rem $solid, map-get($theme-colors, "success"), "bottom");
    @include border(0.125rem $solid, map-get($theme-colors, "success"), "top");
}

.alert-dismissible .close {
    opacity: $one;
    padding: $width-half $size-one $zero $zero;
    :focus {
        outline: $none;
    }
}

@media only screen and (min-width: 37.5rem) {
    .message-container {
        min-height: $size-three + 0.125;
        p {
            font-size: $size-one + 0.125;
            line-height: $size-one + 0.5;
            margin-left: $size-five + 0.625;
        }
    }
}
