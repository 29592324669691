$auto: auto;
$zero: 0;
$quarter: 0.25rem;
$half: 0.5rem;
$xs: 0.75rem;
$small: 1rem;
$smallqtr: 1.25rem;
$smallhlf: 1.5rem;
$xm: 1.75rem;
$medium: 2rem;
$mediumqtr: 2.25rem;
$mediumhlf: 2.5rem;
$xl: 2.75rem;
$large: 3rem;
$largeqtr: 3.25rem;
$largehlf: 3.5rem;
$xxl: 3.75rem;
$xlarge: 4rem;

@mixin space($type: margin, $direction: all, $amount: $medium) {
    @if $type == padding {
        @if $direction == all {
            padding: $amount;
        } @else if $direction == top {
            padding-top: $amount;
        } @else if $direction == left {
            padding-left: $amount;
        } @else if $direction == right {
            padding-right: $amount;
        } @else {
            padding-bottom: $amount;
        }
    } @else {
        @if $direction == all {
            margin: $amount;
        } @else if $direction == top {
            margin-top: $amount;
        } @else if $direction == left {
            margin-left: $amount;
        } @else if $direction == right {
            margin-right: $amount;
        } @else {
            margin-bottom: $amount;
        }
    }
}
