$clear: both;
$zero: 0;
$one: 1;
$percentage: 10%;
$none: none;
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 600;
$italic: italic;
$hidden: hidden;
$auto: auto;
$center: center;
$left: left;
$right: right;
$ellipsis: ellipsis;
$pointer: pointer;
$all: all;
$underline: underline;
$transparent: transparent;
$no-repeat: no-repeat;
$nowrap: nowrap;
$text-bottom: text-bottom;
$solid: solid;
$flex-start: flex-start;
$view-height: 100vh;
$width: 0.625rem;
$font: 1rem;
$size-quarter: 0.25rem;
$width-half: 0.5rem;
$max-width: 20.938rem;
$one-pixel: 0.063rem;
$size-one: 1rem;
$size-two: 2rem;
$size-three: 3rem;
$size-four: 4rem;
$size-five: 5rem;
$size-six: 6rem;
$shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.16), 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.12);
$logo-height: 3.75rem;
$logo-img: 3.938rem;
