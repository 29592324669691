.left-sidebar {
    @include align-items(center);
    @include box-align(center);
    @include flex-direction(row);
    @include flex-wrap(wrap);
    background: map-get($theme-colors, "primary");
    display: map-get($display, "none");
    height: 90vh;
    left: $auto;
    min-height: $percentage * 9.2;
    position: map-get($position, "fixed");
    right: $zero;
    overflow: auto;
    top: 3.75rem;
    width: $width * 9;
    z-index: $one * 9;
    .left-nav {
        align-self: $flex-start;
        width: $percentage * 10;
        ul {
            @include space(margin, all, $zero);
            list-style-type: $none;
            padding: $size-quarter $zero $zero;
            li {
                padding: 0.25rem $zero;
                text-align: $center;
                display: table;
                width: 100%;
                min-height: 85px;
                a {
                    display: table-cell;
                    vertical-align: middle;
                }
                img.img-active {
                    display: $none;
                }
                img {
                    // display: inline-block;
                    display: block;
                    margin: 0 auto;
                }
                span.icon-subhead {
                    color: map-get($theme-colors, "white");
                    display: map-get($display, "inline-block");
                    font-size: 0.875rem;
                    padding: $width $zero $zero;
                    width: $percentage * 9;
                    line-height: 1.125rem;
                }
                .icon.home {
                    fill: map-get($theme-colors, "white");
                    height: $size-one + 0.75;
                    width: $size-one + 0.75;
                }
                .icon {
                    fill: map-get($theme-colors, "white");
                }
            }
            li:first-child {
                max-height: 115px !important;
            }
            li:first-child a {
                cursor: default !important;
            }
            li:first-child img {
                border-radius: 50%;
                width: $width * 4;
                height: $width * 4;
            }
            li.active {
                background: map-get($theme-colors, "white");
                img {
                    display: $none;
                }
                img.img-active {
                    // display: inline-block;
                    display: block;
                    text-align: center;
                }
                span.icon-subhead {
                    color: map-get($theme-colors, "grey");
                }
                .icon.home {
                    fill: map-get($theme-colors, "grey");
                }
                .icon.reports {
                    fill: map-get($theme-colors, "white");
                }
            }
            li.active:hover {
                background-color: #00659a;
                span.icon-subhead {
                    color: map-get($theme-colors, "white");
                }
                img.img-active {
                    display: $none;
                }
                img {
                    display: inline-block;
                }
            }
            li:hover {
                background-color: #00659a;
                max-height: 86px;
                cursor: $pointer;
                .icon.home {
                    fill: map-get($theme-colors, "grey");
                }
                .icon.reports {
                    fill: map-get($theme-colors, "white");
                }
            }
            li:first-child:hover {
                background-color: #00659a;
                color: map-get($theme-colors, "white");
                max-height: 155px !important;
            }
            li:first-child:hover span.icon-subhead {
                color: map-get($theme-colors, "white");
            }
        }
    }
}

@media only screen and (min-width: 48rem) {
    .left-sidebar {
        display: map-get($display, "block");
        left: $zero;
        right: $auto;
        top: $size-three + 0.75;
        float: $left;
    }
    .header .navigation-logo {
        display: map-get($display, "none");
    }
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .left-nav {
        position: absolute !important;
        overflow-y: $auto;
        height: 80%;
    }
}
span.profile-fnt {
    font-size: 0.75rem !important;
}
span.profile-fnt:hover {
    font-size: 0.75rem !important;
}
.left-sidebar::-webkit-scrollbar {
    width: 0.4em;
    scrollbar-width: 0.4em;
}
/*
.left-sidebar {
    scrollbar-color: rgb(199, 196, 196) rgb(122, 126, 122);
    scrollbar-width: thin;
}*/

.left-sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.left-sidebar::-webkit-scrollbar-thumb {
    background-color: #e2e7ec;
    outline: 1px solid #acafb1;
}
