.tooltip-inner {
    background-color: map-get($theme-colors, "light-grey") !important;
    box-shadow: 0px 3px 6px #00000029;
    color: map-get($theme-colors, "grey") !important;
    font-size: $font;
    font-weight: $normal;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    background-color: map-get($theme-colors, "light-grey") !important;
    display: map-get($display, "none");
}
span.tooltip-container {
    @include border-radius(0.15rem);
    background: map-get($theme-colors, "light-grey");
    box-shadow: 0px 3px 6px #00000029;
    display: map-get($display, "inline-block");
    padding: 0.313rem 0.625rem;
    position: map-get($position, "relative");
    top: $zero;
    font-size: $font + 0.125;
    font-weight: $normal;
    color: map-get($theme-colors, "grey");
}
@media screen and (max-width: 37.5rem) {
    span.tooltip-container {
        @include border-radius($zero);
        background: map-get($theme-colors, "light-grey") !important;
        color: map-get($theme-colors, "danger");
        display: map-get($display, "inline-block");
        padding: 0.313rem 0.625rem;
        position: map-get($position, "relative");
    }
}
